.delivery-radio {
  display: flex;
  gap: get-vw(45px);

  @include respond-max('tablet') {
    flex-direction: column;
    gap: get-vw(20px, $mob);
  }
}

.delivery-radio-label {
  cursor: pointer;
  position: relative;

  input {
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 0;
    opacity: 0;

    &:checked {
      & + .delivery-radio-label__box {
        background-image: url('../images/delivery-mask-active.svg');
      }
    }
  }

  &__box {
    width: get-vw(320px);
    height: get-vw(112px);
    padding: get-vw(10px) get-vw(20px);
    text-align: center;
    background-image: url('../images/delivery-mask.svg');
    background-repeat: no-repeat;
    background-size: 100%;

    @include respond-max('tablet') {
      width: get-vw(300px, $mob);
      height: get-vw(108px, $mob);
      padding: get-vw(10px, $mob) get-vw(20px, $mob);
    }
  }

  &__title {
    font-size: get-vw(24px);
    font-weight: 500;
    margin-bottom: get-vw(15px);

    @include respond-max('tablet') {
      font-size: get-vw(24px, $mob);
      margin-bottom: get-vw(15px, $mob);
    }
  }

  &__text {
    font-family: $montserrat;
    font-size: get-vw(16px);
    font-weight: 400;

    @include respond-max('tablet') {
      font-size: get-vw(16px, $mob);
    }
  }
}
