.catalog-info-table {
  &__row {
    display: flex;
    margin-bottom: get-vw(30px);

    @include respond-max('tablet') {
      margin-bottom: get-vw(20px, $mob);
    }
  }

  &__cell {
    font-size: get-vw(21px);
    color: #E0E0E0;

    @include respond-max('tablet') {
      font-size: get-vw(14px, $mob);
    }
    
    &:first-child {
      width: 47.5%;
      font-weight: 300;
    }

    &:nth-child(2) {
      width: get-vw(40px);
      font-weight: 300;

      @include respond-max('tablet') {
        width: get-vw(40px, $mob);
      }
    }

    &:nth-child(3) {
      text-align: right;
      flex: 1;
    }
  }
}