.catalog-text-scroll {
  height: get-vw(500px);

  @include respond-max('tablet') {
    height: get-vw(300px, $mob);
    padding-right: get-vw(15px, $mob);
  }

  @include respond-min('tablet') {
    .simplebar-track.simplebar-vertical {
      right: auto;
      left: get-vw(-40px);
    }
  }
}

.catalog-text {
  line-height: get-vw(20px);

  @include respond-max('tablet') {
    line-height: get-vw(20px, $mob);
  }

  p {
    letter-spacing: 0;

    &:not(:last-child) {
      margin-bottom: get-vw(20px);

      @include respond-max('tablet') {
        margin-bottom: get-vw(20px, $mob);
      }
    }
  }

  b {
    font-weight: 600;
  }
}
