.tt-menu {
  width: 100%;
  border: 0.05208vw solid #9B9B9B;
  text-align: left;
  max-height: 350px;
  overflow: auto;
  color: color('white', 'base');
  background-color: color('black', 'base');
  border-radius: 0.26042vw;
}

.tt-suggestion {
  padding: get-vw(10px);
  cursor: pointer;
}

.twitter-typeahead {
  width: 100%;
}
