.form-input {
  width: 100%;
  font-size: get-vw(12px);
  color: color('white', 'base');
  background-color: transparent;
  border: get-vw(1px) solid color('gray', 'light');
  height: get-vw(28px);
  padding-left: get-vw(10px);
  padding-right: get-vw(10px);
  border-radius: get-vw(5px);

  @include respond-max('tablet') {
    font-size: get-vw(12px, $mob);
    border: get-vw(1px, $mob) solid color('gray', 'light');
    height: get-vw(28px, $mob);
    padding-left: get-vw(10px, $mob);
    padding-right: get-vw(10px, $mob);
    border-radius: get-vw(5px, $mob);
  }
}

.form-field {
  position: relative;

  &__select,
  &__input {
    width: 100%;
    height: get-vw(49px);
    color: color('white', 'base');
    background-color: transparent;
    border: get-vw(1px) solid color('gray', 'light');
    // padding-top: get-vw(5px);
    padding-left: get-vw(20px);
    padding-right: get-vw(20px);
    border-radius: get-vw(5px);
    transition: 0.3s ease-out;

    @include respond-max('tablet') {
      height: get-vw(40px, $mob);
      font-size: get-vw(11px, $mob);
      border: get-vw(1px, $mob) solid color('gray', 'light');
      // padding-top: get-vw(5px, $mob);
      padding-left: get-vw(5px, $mob);
      padding-right: get-vw(20px, $mob);
      border-radius: get-vw(5px, $mob);
    }

    &:focus {
      border-color: #fff;
    }

    // &:focus ~ .form-field__label,
    // &:not(:placeholder-shown) ~ .form-field__label {
    //   transform: translate3d(0, get-vw(-24px), 0) scale(0.6);

    //   @include respond-max('tablet') {
    //     transform: translate3d(0, get-vw(-18px, $mob), 0) scale(0.6);
    //   }
    // }
  }

  &__label {
    // position: absolute;
    // top: 0;
    // bottom: 0;
    font-size: get-vw(15px);
    // left: get-vw(20px);
    font-weight: 500;
    display: flex;
    align-items: center;
    pointer-events: none;
    color: #bfc0c4;
    // transition: 0.3s ease-out;
    // transform-origin: left bottom;
    margin-bottom: get-vw(15px);

    @include respond-max('tablet') {
      // font-size: get-vw(15px);
      // left: get-vw(5px, $mob);
      font-size: get-vw(13px, $mob);
      margin-bottom: get-vw(5px, $mob);
    }
  }

  &__hint {
    font-weight: 300;
    font-size: get-vw(10px);
    color: #bfc0c4;
    margin-top: get-vw(10px);

    @include respond-max('tablet') {
      font-size: get-vw(10px, $mob);
      margin-top: get-vw(10px, $mob);
    }
  }
}

textarea.form-field__input {
  padding: get-vw(20px);
  height: get-vw(225px);

  @include respond-max('tablet') {
    padding: get-vw(5px, $mob);
    height: get-vw(175px, $mob);
  }
}

select.form-field__select {
  outline: none;
  cursor: pointer;
  -webkit-appearance: none;
  background-image: url('../images/icons/select-arrow.svg');
  background-repeat: no-repeat;
  background-size: get-vw(20px) get-vw(10px);
  background-position: center right get-vw(15px);

  @include respond-max('tablet') {
    background-size: get-vw(18px, $mob) get-vw(7px, $mob);
    background-position: center right get-vw(5px, $mob);
  }
}

.form-checkbox {
  position: relative;
  display: inline-block;
  cursor: pointer;
  user-select: none;

  a {
    color: #5E809F;
  }

  &__text {
    color: #bfc0c4;
    font-size: get-vw(12px);
    line-height: get-vw(16px);
    font-weight: 300;
    display: inline-block;
    padding-left: get-vw(36px);
    padding-top: get-vw(3px);
    transition: 0.3s ease-out;

    b {
      font-weight: 500;
    }

    @include respond-max('tablet') {
      font-size: get-vw(12px, $mob);
      line-height: get-vw(16px, $mob);
      padding-left: get-vw(36px, $mob);
      padding-top: get-vw(3px, $mob);
    }

    &:hover {
      color: #fff;

      &:before {
        border-color: #fff;
      }
    }

    &:before {
      content: '';
      width: get-vw(21px);
      height: get-vw(21px);
      position: absolute;
      top: 0;
      left: 0;
      border: 1px solid #3d3d3e;
      border-radius: get-vw(5px);
      transition: 0.3s ease-out;

      @include respond-max('tablet') {
        width: get-vw(21px, $mob);
        height: get-vw(21px, $mob);
        border-radius: get-vw(5px, $mob);
      }
    }

    &:after {
      content: '';
      position: absolute;
      left: get-vw(3px);
      top: get-vw(3px);
      width: get-vw(16px);
      height: get-vw(16px);
      background: url('../images/icons/checked.svg') no-repeat;
      background-size: 100%;
      transition: 0.3s ease-out;
      opacity: 0;
      transform: scale(1.2);

      @include respond-max('tablet') {
        left: get-vw(3px, $mob);
        top: get-vw(3px, $mob);
        width: get-vw(16px, $mob);
        height: get-vw(16px, $mob);
      }
    }
  }

  input {
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 0;
    opacity: 0;

    &:checked {
      & + .form-checkbox__text {
        color: #fff;

        &:before {
          border-color: #fff;
        }

        &:after {
          opacity: 1;
          transform: scale(1);
        }
      }
    }
  }
}

.is-error {
  .form-field__input,
  .form-field__select {
    border-color: color('red', 'base');
  }
}

.label-error {
  display: block;
  font-size: get-vw(10px);
  color: color('red', 'base');
  font-weight: 500;
  margin-top: get-vw(10px);

  @include respond-max('tablet') {
    font-size: get-vw(10px, $mob);
    margin-top: get-vw(10px, $mob);
  }
}
