.cart-aside {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 11;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
    linear-gradient(90deg, #737682 0%, rgba(0, 0, 0, 0) 100%),
    linear-gradient(0deg, rgba(128, 148, 155, 0.2), rgba(128, 148, 155, 0.2));
  transition: 0.8s cubic-bezier(0.165, 0.84, 0.44, 1);
  // overflow: auto;
  opacity: 0;
  pointer-events: none;

  @include respond-max('tablet') {
    background: #141414;
    padding: get-vw(74px, $mob) get-vw(10px, $mob) get-vw(10px, $mob);
    z-index: 5;
  }

  &.is_opened {
    opacity: 1;
    pointer-events: all;

    @include respond-min('tablet') {
      &:before,
      .cart-aside__box {
        transform: translate3d(0, 0, 0);
        transition-delay: 0.3s;
      }
    }

    @include respond-max('tablet') {
      &:before,
      .cart-aside__box {
        transform: translate3d(0, 0, 0);
      }
    }
  }

  &:before {
    content: '';
    position: absolute;
    width: get-vw(422px);
    top: 0;
    bottom: 0;
    right: 0;
    box-shadow: get-vw(-100px) 0 get-vw(200px) rgba(255, 255, 255, 0.05);
    transition: 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
    transform: translate3d(100%, 0, 0);

    @include respond-max('tablet') {
      display: none;
    }
  }

  &__overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &__box {
    margin-left: auto;
    background-image: url('../images/cart/cart-background.svg');
    background-repeat: no-repeat;
    background-size: 100%;
    width: get-vw(462px);
    height: get-vw(1080px);
    position: relative;
    z-index: 2;
    padding: get-vw(33px) get-vw(20px) get-vw(33px) get-vw(56px);
    transition: 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
    transform: translate3d(100%, 0, 0);

    @include respond-max('tablet') {
      transform: translate3d(0, 20px, 0);
      width: 100%;
      height: get-vw(900px, $mob);;
      background-image: url('../images/cart/cart-background-mob.svg');
      padding: get-vw(10px, $mob) get-vw(10px, $mob) get-vw(30px, $mob);
    }
  }

  &__header {
    position: relative;
    color: #7c7d7f;
    border-bottom: get-vw(1px) solid #7c7d7f;
    padding-bottom: get-vw(50px);
    margin-bottom: get-vw(45px);

    @include respond-max('tablet') {
      border-bottom: get-vw(1px, $mob) solid #7c7d7f;
      padding-bottom: get-vw(10px, $mob);
      margin-bottom: 0;
    }

    .icon-cart-arrow-rb {
      width: get-vw(8px);
      height: get-vw(8px);

      @include respond-max('tablet') {
        width: get-vw(8px, $mob);
        height: get-vw(8px, $mob);
      }
    }
  }

  &__username {
    @include respond-max('tablet') {
      font-size: get-vw(8px, $mob);
      margin-top: get-vw(4px, $mob);
    }
  }

  &__title {
    text-transform: uppercase;

    @include respond-max('tablet') {
      font-size: get-vw(28px, $mob);
    }
  }

  &__close {
    position: absolute;
    top: 0;
    right: 0;
    width: get-vw(65px);
    height: get-vw(58px);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background: url('../images/icons/close-border-wrapper.svg') no-repeat;
    background-size: 100%;

    @include respond-max('tablet') {
      width: get-vw(30px, $mob);
      height: get-vw(27px, $mob);
    }

    @media (hover: hover) {
      &:hover {
        .icon-burger-close {
          color: #fff;
          transform: rotate(180deg);
        }
      }
    }

    .icon-burger-close {
      width: get-vw(15px);
      height: get-vw(15px);
      transition: 0.8s cubic-bezier(0.165, 0.84, 0.44, 1);

      @include respond-max('tablet') {
        width: get-vw(8px, $mob);
        height: get-vw(8px, $mob);
      }
    }
  }

  &__body {
    height: get-vw(570px);
    border-bottom: get-vw(1px) solid #7c7d7f;

    @include respond-max('tablet') {
      height: get-vw(380px, $mob);
      margin-bottom: get-vw(15px, $mob);
      border-bottom: get-vw(1px, $mob) solid #7c7d7f;
    }

    .simplebar-track.simplebar-vertical {
      right: auto;
      left: get-vw(-35px);
      bottom: get-vw(50px);

      @include respond-max('tablet') {
        display: none;
      }
    }
  }

  &__products {
    display: flex;
    flex-direction: column;
    gap: get-vw(30px);
    padding-bottom: get-vw(30px);

    @include respond-max('tablet') {
      gap: get-vw(28px, $mob);
      padding-top: get-vw(10px, $mob);
      padding-bottom: get-vw(20px, $mob);
    }
  }

  &__footer {
  }

  &__total {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include respond-max('tablet') {
      margin-bottom: get-vw(15px, $mob);
    }
  }

  &__total-price {
    width: get-vw(213px);
    height: get-vw(34px);
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url('../images/order/order-price-bg.svg');
    background-repeat: no-repeat;
    background-size: contain;

    @include respond-max('tablet') {
      width: get-vw(160px, $mob);
      height: get-vw(27px, $mob);
      font-size: get-vw(14px, $mob);
      background-image: url('../images/order/order-total-price-bg-m.svg');
    }
  }

  &__shipping {
    min-height: get-vw(38px);
    background-color: #3d3d3e;
    border-radius: get-vw(5px);
    color: #bfc0c4;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: get-vw(5px) get-vw(10px);

    @include respond-max('tablet') {
      min-height: get-vw(38px, $mob);
      border-radius: get-vw(5px, $mob);
      padding: get-vw(5px, $mob) get-vw(10px, $mob);
    }
  }

  &__button {
    display: flex;
    justify-content: center;
    position: relative;
    z-index: 2;
  }
}
