.personal-appeal-radios {
  display: flex;
  justify-content: space-between;

  @include respond-max('tablet') {
    flex-direction: column;
    gap: get-vw(20px, $mob);
  }

  &__col {
    width: get-vw(333px);
    display: flex;
    flex-direction: column;
    gap: get-vw(26px);

    @include respond-max('tablet') {
      width: 100%;
      justify-content: center;
      align-items: center;
      gap: get-vw(20px, $mob);
    }
  }
}

.personal-appeal-radios-label {
  cursor: pointer;

  input {
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 0;
    opacity: 0;

    &:checked + .personal-appeal-radios-label__text {
      font-weight: 700;
      background-image: url('../images/personal/personal-appeal-radios-label-active.svg');
    }
  }

  &__text {
    width: get-vw(333px);
    height: get-vw(62px);
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: $montserrat;
    font-size: get-vw(17px);
    font-weight: 400;
    line-height: get-vw(21px);
    letter-spacing: 0.05em;
    color: #edeef1;
    background-image: url('../images/personal/personal-appeal-radios-label.svg');
    background-repeat: no-repeat;
    background-size: 100%;

    @include respond-max('tablet') {
      width: get-vw(250px, $mob);
      height: get-vw(47px, $mob);
      font-size: get-vw(13px, $mob);
      line-height: get-vw(18px, $mob);
    }
  }
}
