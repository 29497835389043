$colors: (
  'black': (
    'base': #090A10,
  ),
  'blue': (
    'sky': #5E809F,
  ),
  'white': (
    'base': #fff,
    'dark': #f2f2f2,
    'm-dark': #F7F7F7,
    'x-dark': #e6e6e6
  ),
  'gray': (
    'dark': #000a19,
    'base': #303030,
    'light': #9B9B9B,
    'm-light': #606060,
    'x-light': #BCBCBC
  ),
  'red': (
    'base': #E85858,
    'light': #ff4a4a,
    'dark': #602020
  ),
  'green': (
    'base': #52ae32,
    'dark': #00903a
  ),
  'yellow': (
    'base': #ffed00,
    'light': #ffe555,
    'dark': #bccf05,
    'x-dark': #a3b504
  ),
  'orange': (
    'base': #ff7f00
  ),
  'purple': (
    'base': #800080
  )
);

$input-colors: (
  'black': #303030,
  'white': #fff,
  'red': #e51616,
  'gold': linear-gradient(45deg, #b8860b, #ffbd19),
  'silver': linear-gradient(45deg, #90949c, #dadbde),
  'brown': #804e2c,
  'gray': #c7c7c7,
  'burgundy': #9d1212,
  'sky-blue': #1da1f2,
  'green': #00903a,
  'blue': #0057ff,
  'beige': #f4edcb,
  'multi': linear-gradient(to bottom, #52ae32, #ffed00),
  'bronze': #3f2109,
  'transparent': linear-gradient(to bottom, #ffffff, #e0e0e0),
  'ginger': #b06500,
  'pink': #ffc0cb,
  'khaki': #c3b091,
  'yellow': #ffff00,
  'purple': #9f00c5,
  'dark-blue': #00008b,
  'nickel': #b5b6b5,
  'crimson': #dc143c,
  'dark-brown': #654321
);

$social-colors: (
  'facebook': #1877f2,
  'twitter': #1da1f2,
  'instagram': #c32aa3,
  'googleplus': #db4437,
  'vk': #4a76a8,
  'odnoklassniki': #ed812b
);
