.button {
  border: none;
  outline: none;
  text-decoration: none;
  user-select: none;

  &__text {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.button-lime {
  width: get-vw(327px);
  height: get-vw(50px);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: get-vw(20px);
  font-weight: 700;
  color: color('black');
  text-transform: lowercase;
  font-family: $montserrat;
  background: url('../images/button-lime.svg') no-repeat;
  background-size: 100%;

  @media (hover:hover) {
    transition: .3s ease-out;

    &:hover {
      transform: scale(1.05);
    }
  }

  @include respond-max('tablet') {
    width: get-vw(224px, $mob);
    height: get-vw(34px, $mob);
    font-size: get-vw(14px, $mob);
  }
}

.button-lime_size_s {
  width: get-vw(179px);
  height: get-vw(50px);
  background: url('../images/button-lime_s.svg') no-repeat;
  background-size: 100%;

  @include respond-max('tablet') {
    width: get-vw(224px, $mob);
    height: get-vw(34px, $mob);
    background: url('../images/button-lime_s_mob.svg') no-repeat;
    background-size: 100%;
  }
}

.button-lime_theme_fut {
  width: get-vw(248px);
  height: get-vw(50px);
  font-size: get-vw(16px);
  text-transform: uppercase;
  background: url('../images/button-lime-fut.svg') no-repeat;
  background-size: 100%;

  @include respond-max('tablet') {
    width: get-vw(224px, $mob);
    height: get-vw(34px, $mob);
    font-size: get-vw(16px, $mob);
    background: url('../images/button-lime_s_mob.svg') no-repeat;
    background-size: 100%;
  }
}

.button-gray,
.button-gray2 {
  width: get-vw(310px);
  height: get-vw(48px);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: get-vw(19px);
  font-weight: 700;
  color: color('white');
  text-transform: lowercase;
  font-family: $montserrat;

  @include respond-max('tablet') {
    width: get-vw(310px, $mob);
    height: get-vw(48px, $mob);
    font-size: get-vw(16px, $mob);
  }
}

.button-gray {
  background: url('../images/button-gray.svg') no-repeat;
  background-size: 100%;
}

.button-gray2 {
  background: url('../images/button-gray2.svg') no-repeat;
  background-size: 100%;
}

.button-metal {
  width: get-vw(302px);
  height: get-vw(46px);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: get-vw(19px);
  font-weight: 700;
  color: color('white');
  text-transform: lowercase;
  font-family: $montserrat;
  background: url('../images/button-metal.svg') no-repeat;
  background-size: 100%;

  @include respond-max('tablet') {
    width: get-vw(302px, $mob);
    height: get-vw(46px, $mob);
    font-size: get-vw(16px, $mob);
  }
}

.button-custom {
  width: get-vw(341px);
  height: get-vw(50px);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: get-vw(18px);
  font-weight: 700;
  color: color('black');
  text-transform: lowercase;
  font-family: $montserrat;
  background: url('../images/button-custom.svg') no-repeat;
  background-size: 100%;

  @media (hover:hover) {
    transition: .3s ease-out;

    &:hover {
      transform: scale(1.05);
    }
  }

  @include respond-max('tablet') {
    width: get-vw(238px, $mob);
    height: get-vw(37px, $mob);
    font-size: get-vw(14px, $mob);
    background: url('../images/button-custom-mob.svg') no-repeat;
    background-size: 100%;
  }
}

.button-custom_not_active {
  pointer-events: none;
  color: white;
  background: url('../images/button-custom_not_active.svg') no-repeat;
  background-size: 100%;

  @include respond-max('tablet') {
    background: url('../images/button-custom-mob_not_active.svg') no-repeat;
    background-size: 100%;
  }
}

.button-available {
  width: get-vw(233px);
  height: get-vw(50px);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: get-vw(20px);
  font-weight: 400;
  color: #edeef1;
  text-transform: lowercase;
  background: url('../images/button-available.svg') no-repeat;
  background-size: 100%;

  @include respond-max('tablet') {
    width: get-vw(238px, $mob);
    height: get-vw(37px, $mob);
    font-size: get-vw(14px, $mob);
    background: url('../images/button-available-mob.svg') no-repeat;
    background-size: 100%;
  }
}

.button-sky {
  width: get-vw(213px);
  height: get-vw(34px);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: get-vw(18px);
  color: #5e809f;
  font-family: $montserrat;
  background: url('../images/button-sky.svg') no-repeat;
  background-size: 100%;

  @include respond-max('tablet') {
    width: get-vw(170px, $mob);
    height: get-vw(28px, $mob);
    font-size: get-vw(16px, $mob);
  }
}

.button-blue {
  width: get-vw(283px);
  height: get-vw(43px);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: get-vw(18px);
  color: #fff;
  font-family: $montserrat;
  background: url('../images/button-blue.svg') no-repeat;
  background-size: 100%;

  @include respond-max('tablet') {
    width: get-vw(224px, $mob);
    height: get-vw(34px, $mob);
    font-size: get-vw(14px, $mob);
  }
}

.button-menu {
  width: get-vw(302px);
  height: get-vw(46px);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: get-vw(18px);
  font-weight: 700;
  color: #fff;
  font-family: $montserrat;
  background: url('../images/button-menu.svg') no-repeat;
  background-size: 100%;

  @include respond-max('tablet') {
    width: get-vw(302px, $mob);
    height: get-vw(46px, $mob);
    font-size: get-vw(18px, $mob);
  }
}

.button-menu_2 {
  background: url('../images/button-menu-2.svg') no-repeat;
  background-size: 100%;
}
