.password-hard {
  margin-bottom: get-vw(25px);
  display: none;

  @include respond-max('tablet') {
    margin-bottom: get-vw(25px, $mob);
  }

  &__indicators {
    display: flex;
    justify-content: space-between;
    margin: get-vw(10px) 0 get-vw(15px);

    @include respond-max('tablet') {
      margin: get-vw(10px, $mob) 0 get-vw(15px, $mob);
    }
  }

  &__indicator-item {
    width: get-vw(80px);
    height: get-vw(4px);
    background: url('../images/auth/pass-indicator.svg') no-repeat;
    background-size: 100%;

    @include respond-max('tablet') {
      width: get-vw(70px, $mob);
      height: get-vw(4px, $mob);
    }

    &.is_active {
      background: url('../images/auth/pass-indicator-active.svg') no-repeat;
      background-size: 100%;
    }
  }

  &__description {
    display: flex;
    flex-direction: column;
    gap: get-vw(8px);
    font-size: get-vw(14px);
    color: #bfc0c4;

    @include respond-max('tablet') {
      gap: get-vw(8px, $mob);
      font-size: get-vw(14px, $mob);
    }
  }

  &__description-item {
    padding-left: get-vw(24px);
    position: relative;

    @include respond-max('tablet') {
      padding-left: get-vw(24px, $mob);
    }

    &:before {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      margin: auto;
      width: get-vw(9px);
      height: get-vw(7px);
      background: url('../images/auth/tick.svg') no-repeat;
      background-size: 100%;

      @include respond-max('tablet') {
        width: get-vw(9px, $mob);
        height: get-vw(7px, $mob);
      }
    }

    &.is_active {
      color: #5e809f;

      &:before {
        background: url('../images/auth/tick-active.svg') no-repeat;
        background-size: 100%;
      }
    }
  }
}
