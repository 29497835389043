.c-blue {
  color: color('blue', 'sky');
}

.c-red {
  color: color('red', 'base');
}

.c-green {
  color: color('green', 'base');
}

.c-gray-light {
  color: color('gray', 'light');
}

.c-gray-x-light {
  color: color('gray', 'x-light');
}

.c-gray-m-light {
  color: color('gray', 'm-light');
}

.c-white-m-dark {
  color: color('white', 'm-dark');
}

.d-flex {
  display: flex;
}

.d-mob-block {
  @include respond-max('tablet') {
    display: block;
  }
}

.flex-column {
  flex-direction: column;
}

.jc-between {
  justify-content: space-between;
}

.jc-center {
  justify-content: center;
}

.jc-end {
  justify-content: flex-end;
}

.ai-center {
  align-items: center;
}

.flex-1 {
  flex: 1;
}

.flex-0-48 {
  flex: 0.48;
}

.gap-20 {
  gap: get-vw(20px);

  @include respond-max('tablet') {
    gap: get-vw(20px, $mob);
  }
}

.tt-upercase {
  text-transform: uppercase;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.mb-120 {
  margin-bottom: get-vw(120px);

  @include respond-max('tablet') {
    margin-bottom: get-vw(120px, $mob);
  }
}

.mb-100 {
  margin-bottom: get-vw(100px);

  @include respond-max('tablet') {
    margin-bottom: get-vw(100px, $mob);
  }
}

.mb-60 {
  margin-bottom: get-vw(60px);

  @include respond-max('tablet') {
    margin-bottom: get-vw(60px, $mob);
  }
}

.mb-50 {
  margin-bottom: get-vw(50px);

  @include respond-max('tablet') {
    margin-bottom: get-vw(50px, $mob);
  }
}

.mb-40 {
  margin-bottom: get-vw(40px);

  @include respond-max('tablet') {
    margin-bottom: get-vw(40px, $mob);
  }
}

.mb-30 {
  margin-bottom: get-vw(30px);

  @include respond-max('tablet') {
    margin-bottom: get-vw(30px, $mob);
  }
}

.mb-25 {
  margin-bottom: get-vw(25px);

  @include respond-max('tablet') {
    margin-bottom: get-vw(25px, $mob);
  }
}

.mb-20 {
  margin-bottom: get-vw(20px);

  @include respond-max('tablet') {
    margin-bottom: get-vw(20px, $mob);
  }
}

.mb-15 {
  margin-bottom: get-vw(15px);

  @include respond-max('tablet') {
    margin-bottom: get-vw(15px, $mob);
  }
}

.mb-10 {
  margin-bottom: get-vw(15px);

  @include respond-max('tablet') {
    margin-bottom: get-vw(15px, $mob);
  }
}

.mb-5 {
  margin-bottom: get-vw(5px);

  @include respond-max('tablet') {
    margin-bottom: get-vw(5px, $mob);
  }
}

.mb-mob-20 {
  @include respond-max('tablet') {
    margin-bottom: get-vw(20px, $mob);
  }
}

.mb-mob-30 {
  @include respond-max('tablet') {
    margin-bottom: get-vw(30px, $mob);
  }
}

.mb-mob-50 {
  @include respond-max('tablet') {
    margin-bottom: get-vw(50px, $mob);
  }
}

.mt-100 {
  margin-top: 100px;

  @include respond-max('tablet') {
    margin-top: get-vw(100px, $mob);
  }
}

.mt-50 {
  margin-top: 50px;

  @include respond-max('tablet') {
    margin-top: get-vw(50px, $mob);
  }
}

.mt-mob-50 {
  @include respond-max('tablet') {
    margin-top: get-vw(50px, $mob);
  }
}

.img {
  max-width: 100%;
  display: block;
}

.visible-mob {
  @include respond-min('tablet') {
    display: none !important;
  }
}

.hide-mob {
  @include respond-max('tablet') {
    display: none !important;
  }
}

.arrow-link {
  cursor: pointer;
  color: #707070;
  transition: .3s ease-out;

  &:hover {
    color: #fff;

    .icon-arrow-link {
      transform: rotate(-45deg);
    }
  }

  .icon-arrow-link {
    width: get-vw(12px);
    height: get-vw(12px);
    margin-left: get-vw(10px);
    vertical-align: middle;
    transition: .3s ease-out;

    @include respond-max('tablet') {
      width: get-vw(12px, $mob);
      height: get-vw(12px, $mob);
      margin-left: get-vw(10px, $mob);
    }
  }
}

.nowrap {
  white-space: nowrap;
}