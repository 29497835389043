.accordion {
  border-top: get-vw(0.38px) solid #7c7d7f;
  border-bottom: get-vw(0.38px) solid #7c7d7f;

  @include respond-max('tablet') {
    border-top: get-vw(0.38px, $mob) solid #7c7d7f;
    border-bottom: get-vw(0.38px, $mob) solid #7c7d7f;
  }

  &.is_disabled {
    .accordion__title {
      font-weight: 400;
      color: #BFC0C4 !important;
    }

    .accordion__header {
      pointer-events: none;
    }

    .accordion__content {
      display: none !important;
    }
  }

  &.accordion_step {
    .accordion__header {
      justify-content: flex-start;
      display: block;

      @include respond-max('tablet') {
        padding-left: get-vw(10px, $mob);
      }

      &:before {
        display: none;
      }

      &.is_active {
        .accordion__title {
          color: #fff;
        }
      }
    }

    .accordion__content {
      padding-top: get-vw(10px);
      padding-left: get-vw(42px);
    }
  }

  &__header {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: get-vw(15px) 0;
    position: relative;

    @include respond-max('tablet') {
      flex-direction: column;
      align-items: flex-start;
      padding: get-vw(15px, $mob) get-vw(15px, $mob) get-vw(15px, $mob)
        get-vw(25px, $mob);
    }

    &.is_active {
      .accordion__title {
        color: #5e809f;
      }

      .accordion__subtext {
        display: none;
      }

      .accordion__header-button {
        display: none;
      }

      .icon-pager-prev {
        transform: rotate(-90deg);
        right: get-vw(5px);

        @include respond-max('tablet') {
          right: get-vw(5px, $mob);
        }
      }
    }

    .icon-pager-prev {
      width: get-vw(9px);
      height: get-vw(17px);
      color: #5e809f;

      @include respond-max('tablet') {
        width: get-vw(9px, $mob);
        height: get-vw(17px, $mob);
      }
    }

    .icon {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      margin: auto;
    }

    &:before {
      width: get-vw(14px);
      height: get-vw(14px);
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      margin: auto;
      background: url('../images/catalog/square-decor.svg') no-repeat;
      background-size: 100%;

      @include respond-max('tablet') {
        width: get-vw(14px, $mob);
        height: get-vw(14px, $mob);
      }
    }
  }

  &__header-button {
    position: absolute;
    top: get-vw(15px);
    right: 0;

    @include respond-max('tablet') {
      position: relative;
      margin-top: get-vw(10px, $mob);
    }
  }

  &__title {
    font-size: get-vw(24px);
    color: #BFC0C4;
    font-weight: 500;

    .js-isCity {
      color: #fff;
    }

    @include respond-max('tablet') {
      font-size: get-vw(20px, $mob);
      text-transform: uppercase;
    }
  }

  &__subtext {
    font-family: $ibm;
    font-size: get-vw(20px);
    font-weight: 500;
    padding-left: get-vw(44px);
    margin-top: get-vw(15px);

    @include respond-max('tablet') {
      font-size: get-vw(16px, $mob);
      padding-left: 0;
      margin-top: get-vw(15px, $mob);
    }
  }

  &__content {
    padding-top: get-vw(30px);
    padding-bottom: get-vw(30px);
    display: none;

    @include respond-max('tablet') {
      padding-top: get-vw(20px, $mob);
      padding-bottom: get-vw(30px, $mob);
    }
  }
}
