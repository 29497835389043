.order-page {
  padding-top: get-vw(124px);
  margin-bottom: get-vw(70px);

  @include respond-max('tablet') {
    padding-top: 0;
    padding-bottom: get-vw(40px, $mob);
  }
}

.order-page-bg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 0;

  img {
    display: block;
    width: 100%;
  }

  @include respond-max('tablet') {
    display: none;
  }
}