.catalog-mob-wrapper {
  padding-bottom: get-vw(30px, $mob);

  @include respond-min('tablet') {
    display: none;
  }
}

.catalog-mob {
  padding: 0 get-vw(20px, $mob);

  &__top {
    margin-bottom: get-vw(140px, $mob);
  }

  &__switcher {
    .icon-pager-prev,
    .icon-pager-next {
      width: get-vw(9px, $mob);
      height: get-vw(17px, $mob);
    }
  }

  &__switcher-prev,
  &__switcher-next {
    width: get-vw(30px, $mob);
    height: get-vw(30px, $mob);
    display: flex;
    justify-content: center;
    align-items: center;
    color: #5e809f;
  }

  &__subtitle {
    letter-spacing: 1em;
    text-align: center;
  }

  &__title {
    text-align: center;
    color: #e0e0e0;
    letter-spacing: 0.12em;
  }

  &__filter {
    // padding: 0 get-vw(15px, $mob);
    margin-bottom: get-vw(30px, $mob);
  }

  &__description {
    color: #bcbcbc;

    p {
      &:not(:last-child) {
        margin-bottom: get-vw(15px, $mob);
      }
    }
  }

  &__description-text {
    overflow: hidden;

    &:not(.is_fulltext) {
      @include line-clamp(3, get-vw(14px, $mob));
    }
  }

  &__description-link {
    font-family: 'Montserrat';
    font-size: get-vw(13px, $mob);
    font-weight: 500;
    line-height: 1;
    text-align: left;
    display: inline-flex;
    align-items: center;
    gap: get-vw(15px, $mob);
    color: #5e809f;
    background-color: transparent;
    margin-top: get-vw(20px, $mob);

    .icon-arrow-more {
      fill: none;
      width: get-vw(7px, $mob);
      height: get-vw(11px, $mob);
    }
  }

  &__products-title {
    color: #7c7d7f;
  }

  &__products-list {
    // padding: 0 get-vw(25px, $mob);
  }

  &__products-pager {
    margin-top: get-vw(40px, $mob);
  }

  &__char {
    padding: 0 get-vw(10px, $mob);
  }

  &__tab {
    margin-left: get-vw(-10px, $mob);
    margin-right: get-vw(-10px, $mob);
    padding: get-vw(10px, $mob);
    background: linear-gradient(
      74.09deg,
      rgba(42, 50, 59, 0.15) 0%,
      rgba(217, 217, 217, 0) 110.86%
    );
    border: get-vw(0.25px, $mob) solid #9b9b9b;
    border-radius: get-vw(10px, $mob);
  }

  &__box {
    margin-left: get-vw(-10px, $mob);
    margin-right: get-vw(-10px, $mob);
    padding: get-vw(20px, $mob) get-vw(10px, $mob);
    background: linear-gradient(
      74.09deg,
      rgba(42, 50, 59, 0.15) 0%,
      rgba(217, 217, 217, 0) 110.86%
    );
    border: get-vw(0.25px, $mob) solid #9b9b9b;
    border-radius: get-vw(10px, $mob);
  }

  &__swiper {
    position: relative;
  }

  &__swiper-svg {
    position: absolute;
    bottom: get-vw(-2px, $mob);
    right: get-vw(-1px, $mob);
    left: 0;
    top: 0;
    // right: 0;
    z-index: 2;
    pointer-events: none;

    svg {
      width: 100%;
      height: auto;
      display: block;
    }
  }

  &__buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: get-vw(15px, $mob);
  }
}

.catalog-mob-char {
  display: flex;
  flex-direction: column;
  gap: get-vw(10px, $mob);
}

.catalog-mob-sizes {
  text-align: center;

  .catalog-size-slider {
    padding: 0;
  }

  .catalog-size-slider__title {
    text-transform: uppercase;
  }

  .catalog-size-slider__swiper {
    position: relative;
    padding: get-vw(10px, $mob) get-vw(20px, $mob);
    border-top: get-vw(0.38px, $mob) solid #7c7d7f;
    border-bottom: get-vw(0.38px, $mob) solid #7c7d7f;

    &:before {
      width: get-vw(14px, $mob);
      height: get-vw(14px, $mob);
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      margin: auto;
      background: url('../images/catalog/square-decor.svg') no-repeat;
      background-size: 100%;
    }
  }
}

// .catalog-mob-sizes {
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   gap: get-vw(32px, $mob);

//   &__item {
//     font-size: get-vw(22px, $mob);
//     color: #7c7d7f;

//     &.is-active {
//       color: #e0e0e0;
//       font-weight: 700;
//       font-size: get-vw(32px, $mob);
//     }
//   }
// }

.catalog-mob-color {
  .icon-switcher-prev,
  .icon-switcher-next {
    width: get-vw(9px, $mob);
    height: get-vw(17px, $mob);
  }

  &__prev,
  &__next {
    width: get-vw(30px, $mob);
    height: get-vw(30px, $mob);
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.catalog-mob-svg-wrapper {
  position: relative;
}

.catalog-mob-svg {
  position: absolute;
  top: get-vw(160px, $mob);
  left: 0;
  right: 0;
  height: get-vw(400px, $mob);
  // overflow: hidden;

  .entering-mob-svg {
    top: 0;
    height: get-vw(300px, $mob);
  }

  .entering-mob-styles {
    margin-bottom: 0;
  }
}
