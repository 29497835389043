.catalog-entering-category {
  .catalog-category {
    &:first-child {
      top: get-vw(105px);
      left: get-vw(713px);

      &:before {
        width: get-vw(22px);
        height: get-vw(22px);
        left: get-vw(-40px);
        top: get-vw(-14px);
      }

      .catalog-category__title {
        font-size: get-vw(12px);
        color: color('gray', 'm-light');
      }

      .catalog-category__tag {
        color: color('white', 'base');
      }
    }

    &:nth-child(2) {
      top: get-vw(173px);
      left: get-vw(435px);

      &:before {
        width: get-vw(74px);
        height: get-vw(74px);
        left: get-vw(-82px);
        top: get-vw(-30px);
      }

      .catalog-category__title {
        font-weight: 700;
      }
    }

    &:nth-child(3) {
      top: get-vw(400px);
      left: get-vw(235px);
      pointer-events: none;

      .catalog-category__content {
        flex-direction: column;
        gap: get-vw(5px);
      }

      .catalog-category__title {
        display: none;
      }

      .catalog-category__tag {
        font-size: get-vw(10px);
        min-width: get-vw(58px);
        height: get-vw(17px);
        background-color: color('blue', 'sky');
      }
    }
  }
}
