.catalog-circles {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  svg,
  img {
    width: 100%;
    height: auto;
    display: block;
  }
}

.catalog-circles-nav {
  .catalog-category {
    display: none;

    &:first-child {
      top: get-vw(72px);
      left: get-vw(420px);
      display: block;

      &:before {
        width: get-vw(22px);
        height: get-vw(22px);
        left: get-vw(-40px);
        top: get-vw(-14px);
      }

      .catalog-category__icon {
        opacity: 0;
      }
    }

    &:nth-child(2) {
      top: get-vw(118px);
      left: get-vw(295px);
      display: block;

      .catalog-category__icon {
        width: get-vw(54px);
        height: get-vw(54px);
        left: get-vw(-78px);
        top: get-vw(-26px);
      }

      .catalog-category__title {
        font-size: get-vw(20px);
      }

      &:before {
        width: get-vw(74px);
        height: get-vw(74px);
        left: get-vw(-82px);
        top: get-vw(-30px);
      }
    }

    &:nth-child(3) {
      top: get-vw(175px);
      left: get-vw(66px);
      pointer-events: none;
      z-index: 3;
      display: block;

      .catalog-category__icon {
        width: get-vw(90px);
        height: get-vw(90px);
      }

      .catalog-category__title {
        display: none;
      }

      .catalog-category__tag {
        font-size: get-vw(10px);
        min-width: get-vw(58px);
        height: get-vw(17px);
        background-color: color('blue', 'sky');
      }
    }
  }
}
