.catalog-entering-style {
  width: get-vw(638px);
  height: get-vw(771px);
  position: absolute;
  top: get-vw(241px);
  left: get-vw(1125px);

  &__title {
    position: absolute;
    top: get-vw(28px);
    left: 0;
    right: 0;
    text-align: center;
    text-transform: uppercase;
  }

  &__image {
    width: 100%;
    height: 100%;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      display: block;
    }
  }
}

.catalog-entering-style-points {
  circle {
    offset-distance: 178.35%;
    offset-path: path(
      'M0,828.737a828.737,828.737 0 1,0 1657.474,0a828.737,828.737 0 1,0 -1657.474,0Z'
    );

    &:first-child {
      offset-distance: 178.35%;
    }

    &:nth-child(2) {
      offset-distance: 93.55%;
    }

    &:nth-child(3) {
      offset-distance: 97.45%;
    }
  }
}