.catalog-product-swiper-wrapper {
  width: 100%;
  height: 100%;
  position: relative;

  @include respond-max('tablet') {
    width: get-vw(320px, $mob);
    height: get-vw(400px, $mob);
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url('../images/mask/product-mask-stroke.svg');
    background-repeat: no-repeat;
    background-size: 100%;
    z-index: 2;
    pointer-events: none;
  }
}

.catalog-product-swiper-decor {
  width: get-vw(13px);
  height: get-vw(172px);
  position: absolute;
  top: get-vw(100px);
  left: get-vw(-12px);

  @include respond-max('tablet') {
    width: get-vw(7px, $mob);
    height: get-vw(95px, $mob);
    top: get-vw(56px, $mob);
    left: get-vw(-6px, $mob);
    z-index: 2;
  }

  svg {
    width: 100%;
    height: auto;
  }
}

.catalog-product-swiper-svg-animate {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  pointer-events: none;

  svg {
    display: block;
    width: 100%;
    height: auto;
  }
}