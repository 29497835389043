.order-plug {
  width: get-vw(1647px);
  margin: 0 auto;

  @include respond-max('tablet') {
    width: get-vw(340px, $mob);
  }

  &_error {
    .order-plug__box {
      @include respond-min('tablet') {
        background-image: url('../images/order/order-plug-bg-error.svg');
      }
    }
  }

  &__box {
    position: relative;
    width: get-vw(1647px);
    height: get-vw(478px);
    padding-top: get-vw(40px);
    background-image: url('../images/order/order-plug-bg.svg');
    background-repeat: no-repeat;
    background-size: 100%;
    margin-bottom: get-vw(40px);

    @include respond-max('tablet') {
      width: get-vw(340px, $mob);
      height: get-vw(438px, $mob);
      padding: get-vw(50px, $mob) get-vw(10px, $mob) 0;
      margin-bottom: get-vw(40px, $mob);
      background-image: url('../images/order/order-plug-bg-mob.svg');

      p {
        font-size: get-vw(16px, $mob);
      }
    }
  }

  &__header {
    @include respond-max('tablet') {
      padding: 0 get-vw(15px, $mob);
      margin-bottom: get-vw(50px, $mob);
    }
  }

  &__title {
    @include respond-max('tablet') {
      font-size: get-vw(20px, $mob);
    }
  }

  &__buttons {
    display: flex;
    justify-content: center;
    gap: get-vw(35px);
    margin: get-vw(35px) 0;

    @include respond-max('tablet') {
      flex-direction: column;
      align-items: center;
      gap: get-vw(10px, $mob);
      margin: get-vw(25px, $mob) 0;
    }
  }

  &__buttons_error {
    @include respond-min('tablet') {
      position: absolute;
      bottom: get-vw(60px);
      left: 0;
      right: 0;
    }

    @include respond-max('tablet') {
      margin-top: get-vw(100px, $mob)
    }
  }

  &__button {
    width: get-vw(283px);
    height: get-vw(43px);
    font-weight: 700;
    color: #edeef1;
    font-size: get-vw(18px);
    font-family: $montserrat;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url('../images/order/order-plug-button.svg');
    background-repeat: no-repeat;
    background-size: 100%;

    @include respond-max('tablet') {
      width: get-vw(283px, $mob);
      height: get-vw(43px, $mob);
      font-size: get-vw(18px, $mob);
    }

    &:nth-child(2) {
      background-image: url('../images/order/order-plug-button-2.svg');
    }
  }

  &__marketplace {
    @include respond-min('tablet') {
      position: absolute;
      bottom: get-vw(35px);
      left: get-vw(120px);
      right: get-vw(120px);
    }

    @include respond-max('tablet') {
      margin-bottom: get-vw(20px, $mob);

      .arrow-link {
        font-size: get-vw(12px, $mob);
      }
    }
  }

  &__progress {
    text-align: center;
    margin-top: get-vw(80px);

    @include respond-max('tablet') {
      margin-top: get-vw(25px, $mob);
    }
  }

  &__progress-number {
    font-size: get-vw(50px);
    font-weight: 600;
    margin-bottom: get-vw(20px);

    @include respond-max('tablet') {
      font-size: get-vw(30px, $mob);
      margin-bottom: get-vw(20px, $mob);
    }
  }

  &__progress-bar {
    max-width: get-vw(740px);
    height: get-vw(6px);
    margin: 0 auto;
    border-radius: get-vw(6px);
    border: get-vw(1px) solid #edeef1;

    @include respond-max('tablet') {
      max-width: 100%;
      height: get-vw(6px, $mob);
      border-radius: get-vw(6px, $mob);
      border: get-vw(1px, $mob) solid #edeef1;
    }
  }

  &__progress-bar-fill {
    height: 100%;
    border-radius: get-vw(6px);
    background-color: #edeef1;

    @include respond-max('tablet') {
      border-radius: get-vw(6px, $mob);
    }
  }
}

.order-plug-button {
  @include respond-max('tablet') {
    display: flex;
    justify-content: center;
    margin-top: get-vw(40px, $mob);
  }
}
