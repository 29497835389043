.catalog-color {
  font-size: get-vw(19px);
  color: #BFC0C4;
  // margin-left: get-vw(-15px);

  @include respond-max('tablet') {
    font-size: get-vw(19px, $mob);
  }

  &__scroll {
    max-height: get-vw(160px);
  }

  &__list {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: get-vw(15px);

    @include respond-max('tablet') {
      gap: get-vw(15px, $mob);
      padding-top: get-vw(15px, $mob);
    }
  }

  &__item {
    position: relative;
    width: get-vw(242px);
    height: get-vw(36px);
    padding-bottom: get-vw(4px);
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url('../images/catalog/color-bg.svg');
    background-repeat: no-repeat;
    background-size: 100%;
    transition: 0.3s ease-out;

    @include respond-max('tablet') {
      width: get-vw(242px, $mob);
      height: get-vw(36px, $mob);
      padding-left: get-vw(15px, $mob);
      padding-bottom: get-vw(4px, $mob);
    }

    &:hover {
      color: #fff;
    }

    // &:before {
    //   content: '';
    //   position: absolute;
    //   top: get-vw(4px);
    //   left: get-vw(-15px);
    //   width: get-vw(5px);
    //   height: get-vw(12px);
    //   background-image: url('../images/icons/color-arrow.svg');
    //   background-repeat: no-repeat;
    //   background-size: 100%;
    //   opacity: 0;
    // }

    &.is_disabled {
      pointer-events: none;
      background-image: url('../images/catalog/color-bg-disabled.svg');
      color: #E85858;
    }

    &.is_active {
      background-image: url('../images/catalog/color-bg-active.svg');
      font-weight: 600;
      color: #edeef1;

      &:before {
        opacity: 1;
      }
    }
  }
}
