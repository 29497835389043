.home-news-card {
  position: relative;
  width: get-vw(150px);
  height: get-vw(355px);
  cursor: pointer;
  transition: 0.3s ease-out;

  @include respond-max('tablet') {
    width: get-vw(150px, $mob);
    height: get-vw(55px, $mob);
  }

  &:not(.home-news-card_is_active) {
    @media (hover: hover) {
      &:hover {
        transform: translate3d(0, -35px, 0);

        .home-news-card__svg-path {
          fill-opacity: 0.68;
        }
      }
    }
  }

  &__code {
    position: absolute;
    z-index: 2;
    top: get-vw(28px);
    left: get-vw(28px);
    font-size: get-vw(17px);
    font-weight: 700;
    color: #7c7d7f;

    .icon-arrow-link {
      width: get-vw(7px);
      height: get-vw(7px);
    }

    @include respond-max('tablet') {
      font-size: get-vw(9px, $mob);
      left: auto;
      top: get-vw(10px, $mob);
      right: get-vw(10px, $mob);
      display: flex;
      align-items: center;
      flex-direction: row-reverse;
      gap: get-vw(5px, $mob);
      color: #e0e0e0;

      .icon-arrow-link {
        width: get-vw(7px, $mob);
        height: get-vw(7px, $mob);
        transform: rotate(90deg);
      }
    }
  }

  &__date {
    position: absolute;
    display: flex;
    align-items: center;
    gap: get-vw(8px);
    top: get-vw(8px);
    right: get-vw(6px);
    color: #7c7d7f;
    font-size: get-vw(10px);
    font-weight: 500;

    &:after {
      content: '';
      width: get-vw(6px);
      height: get-vw(6px);
      background: url('../images/icons/news-card-date.svg') no-repeat;
      background-size: 100%;
    }

    @include respond-max('tablet') {
      font-size: get-vw(6px, $mob);
      transform: rotate(-90deg);
      flex-direction: row-reverse;
      gap: get-vw(8px, $mob);
      top: get-vw(120px, $mob);
      right: get-vw(-13px, $mob);
      opacity: 0;

      &:after {
        width: get-vw(6px, $mob);
        height: get-vw(6px, $mob);
      }
    }
  }

  &__front-title {
    font-weight: 500;
    color: #f1f1f1;
    font-size: get-vw(20px);
    font-family: $montserrat;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;

    @include respond-min('tablet') {
      transform-origin: top right;
      transform: rotate(-90deg);
      padding: 0 get-vw(60px) 0 get-vw(90px);
      top: 0;
      right: get-vw(150px);
      width: get-vw(355px);
      height: get-vw(150px);
    }

    @include respond-max('tablet') {
      width: 100%;
      height: 100%;
      padding: 0 get-vw(30px, $mob) get-vw(7px, $mob) get-vw(30px, $mob);
      font-size: get-vw(10px, $mob);
    }
  }

  &__content {
    width: get-vw(425px);
    height: 100%;
    position: absolute;
    top: get-vw(25px);
    left: get-vw(100px);
    transition: 0.1s ease-out;
    opacity: 0;
    display: flex;
    flex-direction: column;
    padding-bottom: get-vw(60px);

    @include respond-max('tablet') {
      width: get-vw(230px, $mob);
      left: get-vw(33px, $mob);
      top: get-vw(20px, $mob);
      padding-bottom: get-vw(45px, $mob);
    }
  }

  &__title {
    font-weight: 700;
    color: #f1f1f1;
    font-size: get-vw(30px);
    font-family: $montserrat;
    margin-bottom: get-vw(25px);

    @include respond-max('tablet') {
      font-size: get-vw(24px, $mob);
      margin-bottom: get-vw(10px, $mob);
    }
  }

  &__desc {
    font-size: get-vw(20px);
    font-weight: 400;
    line-height: get-vw(26px);
    text-align: left;
    color: #e0e0e0;

    @include respond-max('tablet') {
      font-size: get-vw(12px, $mob);
      line-height: get-vw(15px, $mob);
    }

    p {
      &:not(:last-child) {
        margin-bottom: get-vw(20px);

        @include respond-max('tablet') {
          margin-bottom: get-vw(20px, $mob);
        }
      }
    }
  }

  &__more {
    display: inline-flex;
    align-items: center;
    gap: get-vw(14px);
    font-weight: 500;
    color: #558b97;
    font-size: get-vw(12px);
    margin-top: get-vw(30px);

    @include respond-max('tablet') {
      gap: get-vw(8px, $mob);
      font-size: get-vw(9px, $mob);
      margin-top: get-vw(20px, $mob);
    }

    @media (hover: hover) {
      transition: 0.3s ease-out;

      &:hover {
        transform: scale(1.05);
      }
    }

    .icon-arrow-more {
      fill: none;
      width: get-vw(8px);
      height: get-vw(11px);

      @include respond-max('tablet') {
        width: get-vw(5px, $mob);
        height: get-vw(8px, $mob);
      }
    }
  }

  &__button {
    width: get-vw(237px);
    height: get-vw(37px);
    font-weight: 600;
    font-size: get-vw(20px);
    color: #090A10;
    font-family: $montserrat;
    display: flex;
    align-items: center;
    justify-content: center;
    background: url('../images/button-cat.svg') no-repeat;
    background-size: 100%;
    margin-top: auto;

    @include respond-max('tablet') {
      width: get-vw(152px, $mob);
      height: get-vw(23px, $mob);
      font-size: get-vw(12px, $mob);
    }
  }

  &__right-decor {
    position: absolute;
    top: get-vw(30px);
    right: get-vw(10px);
    color: #7c7d7f;
    font-size: get-vw(4px);
    font-weight: 500;
    transform-origin: top right;
    transform: rotate(-90deg);

    @include respond-max('tablet') {
      display: none;
    }
  }

  &__bottom-decor {
    position: absolute;
    bottom: get-vw(4px);
    right: get-vw(30px);
    color: #7c7d7f;
    font-size: get-vw(4px);
    font-weight: 100;

    @include respond-max('tablet') {
      font-size: get-vw(4px, $mob);
      bottom: get-vw(4px, $mob);
      right: get-vw(12px, $mob);
      opacity: 0;
      transition: 0.3s ease-out;
    }
  }

  &__svg {
    svg {
      width: get-vw(615px);
      height: get-vw(355px);
      display: block;

      @include respond-max('tablet') {
        width: get-vw(298px, $mob);
        height: get-vw(220px, $mob);
      }
    }
  }

  &__svg-path {
    transition: 0.3s ease-out;
  }
}

.home-news-card_is_active {
  width: get-vw(615px);
  cursor: default;

  @include respond-max('tablet') {
    width: get-vw(298px, $mob);
    height: get-vw(220px, $mob);

    .home-news-card__date,
    .home-news-card__bottom-decor {
      opacity: 1;
    }
  }

  .home-news-card__content {
    opacity: 1;
    transition: 0.3s 0.3s ease-out;
  }

  .home-news-card__front-title {
    opacity: 0;
  }

  .home-news-card__svg-path {
    fill-opacity: 0.68;
  }
}
