.is-menu-opened {
  .menu {
    opacity: 1;
    pointer-events: all;
  }

  .menu-layer {
    transform: translate3d(0, 0, 0);
    transition-delay: 0.3s;
  }

  .header-burger__icon {
    opacity: 0;
    transform: scale(0);
  }

  .header-burger__close {
    opacity: 1;
    pointer-events: all;
    transform: scale(1);
  }
}

.menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
    linear-gradient(90deg, #737682 0%, rgba(0, 0, 0, 0) 100%),
    linear-gradient(0deg, rgba(128, 148, 155, 0.2), rgba(128, 148, 155, 0.2));
  transition: 0.8s cubic-bezier(0.165, 0.84, 0.44, 1);
  opacity: 0;
  pointer-events: none;

  @include respond-max('tablet') {
    overflow: auto;
  }
}

.menu-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.menu-layer {
  position: relative;
  width: get-vw(721px);
  height: 100%;
  color: #fff;
  background: #070707;
  border-radius: 0 get-vw(24px) get-vw(24px) 0;
  box-shadow: get-vw(100px) 0 get-vw(200px) rgba(255, 255, 255, 0.05);
  transition: 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  transform: translate3d(-100%, 0, 0);

  @include respond-max('tablet') {
    width: 100%;
    min-height: get-vw(700px, $mob);
    box-shadow: none;
    border: get-vw(1px, $mob) solid rgba(255, 255, 255, 0.25);
    border-left: none;
    border-right: none;
    border-radius: get-vw(20px, $mob);

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: url('../images/menu/menu-mob-lines.svg');
      background-repeat: no-repeat;
      background-size: 100%;
    }
  }

  &__logo {
    @include respond-min('tablet') {
      width: get-vw(177px);
      height: get-vw(87px);
      position: absolute;
      left: get-vw(70px);
      top: get-vw(510px);

      img {
        width: 100%;
      }
    }

    @include respond-max('tablet') {
      width: get-vw(75px, $mob);
      height: get-vw(38px, $mob);
      position: absolute;
      top: get-vw(20px, $mob);
      right: get-vw(23px, $mob);
    }
  }

  &__image {
    width: 100%;
    height: 100%;

    @include respond-max('tablet') {
      display: none;
    }

    img {
      width: 100%;
      display: block;
    }
  }

  &__social {
    position: absolute;
    font-family: $montserrat;
    bottom: get-vw(75px);
    left: get-vw(70px);
    font-size: get-vw(12px);
    font-weight: 400;
    line-height: get-vw(20px);
    text-align: left;
    display: flex;
    flex-direction: column;

    @include respond-max('tablet') {
      font-size: get-vw(12px, $mob);
      line-height: get-vw(20px, $mob);
      left: get-vw(35px, $mob);
      bottom: get-vw(35px, $mob);
    }

    a {
      transition: 0.3s ease-out;

      &:hover {
        opacity: 0.6;
      }
    }
  }

  &__mail {
    position: absolute;
    bottom: get-vw(79px);
    right: get-vw(74px);
    font-size: get-vw(16px);
    color: #e0e0e0;

    @include respond-max('tablet') {
      bottom: get-vw(40px, $mob);
      right: get-vw(23px, $mob);
      font-size: get-vw(16px, $mob);
    }
  }

  &__mail-button {
    width: get-vw(213px);
    height: get-vw(35px);
    display: flex;
    align-items: center;
    justify-content: center;
    background: url('../images/button-white-border.svg') no-repeat;
    background-size: 100%;

    @media (hover:hover) {
      transition: .3s ease-out;
  
      &:hover {
        transform: scale(1.05);
      }
    }

    @include respond-max('tablet') {
      width: get-vw(170px, $mob);
      height: get-vw(28px, $mob);
    }
  }
}

.menu-nav_siblist_visible {
  .menu-nav__sublist-item {
    transition: 1s ease-in;

    &:first-child,
    &:nth-child(2),
    &:nth-child(3) {
      opacity: 1;
      pointer-events: all;
    }

    &:first-child {
      transition-delay: 0.3s;
    }

    &:nth-child(2) {
      transition-delay: 0.8s;
    }

    &:nth-child(3) {
      transition-delay: 1.2s;
    }
  }

  .menu-nav__item {
    &:first-child {
      & > .menu-nav__link {
        .menu-nav__title {
          color: #fff;
          font-weight: 700;
          font-size: get-vw(32px);

          @include respond-max('tablet') {
            font-size: get-vw(22px, $mob);
          }
        }

        .menu-nav__subtitle {
          opacity: 1;
        }
      }
    }

    &:not(:first-child) {
      opacity: 0;
      pointer-events: none;
    }
  }
}

.menu-nav {
  &__item {
    transition: 0.3s ease-out;

    &.is_disabled {
      .menu-nav__dot {
        opacity: 0;
      }

      .menu-nav__link {
        &:before {
          content: '';
          position: absolute;
          top: get-vw(-3px);
          left: get-vw(-12px);
          width: get-vw(18px);
          height: get-vw(21px);
          background-image: url('../images/icons/lock.svg');
          background-repeat: no-repeat;
          background-size: 100%;

          @include respond-max('tablet') {
            top: 0;
            left: 0;
            width: get-vw(13px, $mob);
            height: get-vw(16px, $mob);
          }
        }
      }
    }

    @include respond-min('tablet') {
      &:first-child {
        & > .menu-nav__link {
          top: get-vw(180px);
          left: get-vw(139px);
        }
      }

      &:nth-child(2) {
        & > .menu-nav__link {
          top: get-vw(327px);
          left: get-vw(282px);
        }

        &.is_disabled {
          & > .menu-nav__link {
            left: get-vw(288px);
          }
        }
      }

      &:nth-child(3) {
        & > .menu-nav__link {
          top: get-vw(503px);
          left: get-vw(371px);
        }

        &.is_disabled {
          & > .menu-nav__link {
          left: get-vw(376px);
          }
        }
      }

      &:nth-child(4) {
        & > .menu-nav__link {
          top: get-vw(688px);
          left: get-vw(404px);
        }

        &.is_disabled {
          & > .menu-nav__link {
            left: get-vw(410px);
          }
        }
      }
    }

    @include respond-max('tablet') {
      &:first-child {
        & > .menu-nav__link {
          top: get-vw(205px, $mob);
          left: get-vw(51px, $mob);
        }
      }

      &:nth-child(2) {
        & > .menu-nav__link {
          top: get-vw(302px, $mob);
          left: get-vw(143px, $mob);
        }

        &.is_disabled {
          & > .menu-nav__link {
            left: get-vw(140px, $mob);
          }
        }
      }

      &:nth-child(3) {
        & > .menu-nav__link {
          top: get-vw(420px, $mob);
          left: get-vw(201px, $mob);
        }

        &.is_disabled {
          & > .menu-nav__link {
            left: get-vw(197px, $mob);
          }
        }
      }

      &:nth-child(4) {
        & > .menu-nav__link {
          top: get-vw(547px, $mob);
          left: get-vw(220px, $mob);
        }

        &.is_disabled {
          & > .menu-nav__link {
            left: get-vw(216px, $mob);
          }
        }
      }
    }
  }

  &__sublist-item {
    transition: 0.3s ease-out;
    opacity: 0;
    pointer-events: none;

    @include respond-min('tablet') {
      &:first-child {
        & > .menu-nav__link {
          top: get-vw(327px);
          left: get-vw(282px);
        }
      }

      &:nth-child(2) {
        & > .menu-nav__link {
          top: get-vw(503px);
          left: get-vw(371px);
        }
      }

      &:nth-child(3) {
        & > .menu-nav__link {
          top: get-vw(688px);
          left: get-vw(404px);
        }
      }
    }

    @include respond-max('tablet') {
      &:first-child {
        & > .menu-nav__link {
          top: get-vw(302px, $mob);
          left: get-vw(143px, $mob);
        }
      }

      &:nth-child(2) {
        & > .menu-nav__link {
          top: get-vw(420px, $mob);
          left: get-vw(201px, $mob);
        }
      }

      &:nth-child(3) {
        & > .menu-nav__link {
          top: get-vw(547px, $mob);
          left: get-vw(220px, $mob);
        }
      }
    }
  }

  &__link {
    font-family: $montserrat;
    font-size: get-vw(21px);
    font-weight: 500;
    line-height: get-vw(21px);
    letter-spacing: 0em;
    text-align: left;
    color: #9b9b9b;
    display: inline-flex;
    align-items: center;
    gap: get-vw(8px);
    position: absolute;
    transition: 0.3s ease-out;

    @include respond-max('tablet') {
      font-size: get-vw(16px, $mob);
      line-height: get-vw(19px, $mob);
      gap: get-vw(16px, $mob);
    }

    &:hover {
      color: #fff;

      .menu-nav__title {
        transform: scale(1.05);
      }
    }
  }

  &__dot {
    width: get-vw(6px);
    height: get-vw(6px);
    border: get-vw(1px) solid #9b9b9b;
    border-radius: 50%;

    @include respond-max('tablet') {
      width: get-vw(6px, $mob);
      height: get-vw(6px, $mob);
    }
  }

  &__title {
    transition: 0.3s ease-out;
    transform-origin: left bottom;
  }

  &__subtitle {
    position: absolute;
    color: #fff;
    white-space: nowrap;
    font-size: get-vw(16px);
    top: get-vw(40px);
    right: get-vw(1px);
    opacity: 0;
    transition: 0.3s ease-out;

    @include respond-max('tablet') {
      font-size: get-vw(14px, $mob);
      top: get-vw(25px, $mob);
      right: get-vw(1px, $mob);
    }
  }
}
