.font-disket {
  font-family: $disket;
}

.font-montserrat {
  font-family: $montserrat;
}

.font-rajdhani {
  font-family: $rajdhani;
}

.font-700 {
  font-weight: 700;
}

.font-900 {
  font-weight: 900;
}

.font-600 {
  font-weight: 600;
}

.font-500 {
  font-weight: 500;
}

.text-64 {
  font-size: get-vw(64px);

  @include respond-max('tablet') {
    font-size: get-vw(64px, $mob);
  }
}

.text-52 {
  font-size: get-vw(52px);

  @include respond-max('tablet') {
    font-size: get-vw(52px, $mob);
  }
}

.text-50 {
  font-size: get-vw(50px);

  @include respond-max('tablet') {
    font-size: get-vw(50px, $mob);
  }
}

.text-40 {
  font-size: get-vw(40px);

  @include respond-max('tablet') {
    font-size: get-vw(40px, $mob);
  }
}

.text-34 {
  font-size: get-vw(34px);

  @include respond-max('tablet') {
    font-size: get-vw(34px, $mob);
  }
}

.text-24 {
  font-size: get-vw(24px);

  @include respond-max('tablet') {
    font-size: get-vw(24px, $mob);
  }
}

.text-22 {
  font-size: get-vw(22px);

  @include respond-max('tablet') {
    font-size: get-vw(22px, $mob);
  }
}

.text-20 {
  font-size: get-vw(20px);

  @include respond-max('tablet') {
    font-size: get-vw(20px, $mob);
  }
}

.text-18 {
  font-size: get-vw(18px);

  @include respond-max('tablet') {
    font-size: get-vw(18px, $mob);
  }
}

.text-17 {
  font-size: get-vw(17px);

  @include respond-max('tablet') {
    font-size: get-vw(17px, $mob);
  }
}

.text-16 {
  font-size: get-vw(16px);

  @include respond-max('tablet') {
    font-size: get-vw(16px, $mob);
  }
}

.text-15 {
  font-size: get-vw(15px);

  @include respond-max('tablet') {
    font-size: get-vw(15px, $mob);
  }
}

.text-13 {
  font-size: get-vw(13px);

  @include respond-max('tablet') {
    font-size: get-vw(13px, $mob);
  }
}

.text-12 {
  font-size: get-vw(12px);

  @include respond-max('tablet') {
    font-size: get-vw(12px, $mob);
  }
}

.text-11 {
  font-size: get-vw(11px);

  @include respond-max('tablet') {
    font-size: get-vw(11px, $mob);
  }
}

.text-10 {
  font-size: get-vw(10px);

  @include respond-max('tablet') {
    font-size: get-vw(10px, $mob);
  }
}

.text-8 {
  font-size: get-vw(8px);

  @include respond-max('tablet') {
    font-size: get-vw(8px, $mob);
  }
}

.text-7 {
  font-size: get-vw(7px);

  @include respond-max('tablet') {
    font-size: get-vw(7px, $mob);
  }
}

.text-mob-26 {
  @include respond-max('tablet') {
    font-size: get-vw(20px, $mob);
  }
}

.text-mob-14 {
  @include respond-max('tablet') {
    font-size: get-vw(14px, $mob);
  }
}

.text-mob-12 {
  @include respond-max('tablet') {
    font-size: get-vw(12px, $mob);
  }
}

.text-undreline {
  text-decoration: underline;
}

.text-uppercase {
  text-transform: uppercase;
}

.lh-basic {
  line-height: 1.4;
}

.lh-20 {
  line-height: get-vw(20px);

  @include respond-max('tablet') {
    line-height: get-vw(20px, $mob);
  }
}