.catalog-mob-accordion {
  border-top: get-vw(0.38px, $mob) solid #7c7d7f;
  border-bottom: get-vw(0.38px, $mob) solid #7c7d7f;

  &__header {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: get-vw(15px, $mob) 0;
    position: relative;

    &.is_active {
      .icon-pager-prev {
        transform: rotate(-90deg);
        right: get-vw(5px, $mob);
      }
    }

    .icon-pager-prev {
      width: get-vw(9px, $mob);
      height: get-vw(17px, $mob);
      color: #5e809f;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      margin: auto;
    }

    &:before {
      width: get-vw(14px, $mob);
      height: get-vw(14px, $mob);
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      margin: auto;
      background: url('../images/catalog/square-decor.svg') no-repeat;
      background-size: 100%;
    }
  }

  &__title {
    font-size: get-vw(20px, $mob);
    color: #bfc0c4;
    font-weight: 500;
    text-transform: uppercase;
  }

  &__content {
    padding-bottom: get-vw(30px, $mob);
    display: none;
  }
}
