.catalog-rare {
  &__item {
    cursor: pointer;
    width: 48px;
    height: 48px;
    position: absolute;
    top: 0;
    left: 0;
    offset-rotate: 0deg;
    offset-path: path(
      'M21.125,424.914a474.789,474.789 0 1,0 949.578,0a474.789,474.789 0 1,0 -949.578,0Z'
    );
    transition: .5s transform;
    will-change: offset-distance;

    &:not(.is_active) {
      transform: scale(.5);
    }

    &.is_disabled {
      pointer-events: none;

      img {
        opacity: 0;
      }

      &:before {
        content: '';
        width: 20px;
        height: 20px;
        border-radius: 50%;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        background-color: #222931;
        border: 1.5px solid #7C7D7F;
      }
    }

    &.is_active {
      .catalog-rare__icon-active {
        opacity: 1;
      }
    }
  }

  &__icon-default {
    width: 100%;
    height: 100%;
    display: block;
  }

  &__icon-active {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    opacity: 0;
  }
}