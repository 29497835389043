.catalog-entering-body {
  .page-wrapper {
    height: get-vw(1452px);
    overflow: hidden;
  }

  @include respond-min('tablet') {
    .header-controls {
      display: none;
    }
  }
}

.catalog-entering {
  position: relative;
}

.catalog-entering-svg {
  height: get-vw(1080px);

  svg {
    width: 100%;
    max-width: 100%;
    height: auto;
    display: block;
  }

  .catalog-point {
    offset-distance: 91.6%;
    offset-path: path(
      'M236.666 354.619C150.574 525.688 204.1 746.71 360.495 965.844C516.875 1184.96 776.03 1402.06 1100.9 1565.19C1425.78 1728.31 1754.81 1806.56 2024.15 1801.21C2293.52 1795.87 2503.04 1706.93 2589.13 1535.86C2675.22 1364.79 2621.69 1143.77 2465.3 924.634C2308.92 705.521 2049.76 488.419 1724.89 325.291C1400.02 162.163 1070.98 83.9176 801.642 89.2633C532.276 94.6094 322.758 183.549 236.666 354.619Z'
    );

    &:first-child {
      offset-distance: 91.6%;
    }

    &:nth-child(2) {
      offset-distance: 96.6%;
    }

    &:nth-child(3) {
      offset-distance: 100.87%;
    }
  }
}
