@mixin line-clamp(
  $line-count: 3,
  $line-height: $base-line-height,
  $reset: false
) {
  display: block;
  display: -webkit-box;
  -webkit-line-clamp: $line-count;
  -webkit-box-orient: vertical;
  max-height: $line-count * $line-height;
  overflow: hidden;
  text-overflow: ellipsis;

  @if $reset {
    display: block;
    -webkit-line-clamp: unset;
    -webkit-box-orient: unset;
    max-height: none;
    overflow: visible;
    text-overflow: none;
  }
}
