.personal-infomenu {
  color: #fff;

  &__header {
    width: get-vw(380px);
    height: get-vw(58px);
    display: flex;
    align-items: center;
    justify-content: center;
    background: url('../images/personal/personal-infomenu-header.svg') no-repeat;
    background-size: 100%;
    margin-bottom: get-vw(50px);

    @include respond-max('tablet') {
      width: get-vw(380px, $mob);
      height: get-vw(58px, $mob);
      margin-bottom: get-vw(50px, $mob);
    }
  }

  &__title {
    font-size: get-vw(24px);
    font-weight: 700;
    line-height: get-vw(31px);
    letter-spacing: 0.05em;
    text-transform: uppercase;

    @include respond-max('tablet') {
      font-size: get-vw(24px, $mob);
      line-height: get-vw(31px, $mob);
    }
  }

  &__logo {
    width: get-vw(135px);
    height: get-vw(135px);
    position: relative;
    margin: 0 auto get-vw(30px);

    @include respond-max('tablet') {
      width: get-vw(135px, $mob);
      height: get-vw(135px, $mob);
      margin: 0 auto get-vw(30px, $mob);
    }

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: url('../images/mask/personal-mask-image-border.svg');
      background-repeat: no-repeat;
      background-size: 100%;
      z-index: 1;
    }

    img {
      width: 100%;
      height: 100%;
      display: block;
      object-fit: cover;
    }
  }

  &__logo-mask {
    width: 100%;
    height: 100%;
    mask-image: url('../images/mask/personal-mask-image.svg');
    mask-position: center;
    mask-size: cover;
  }

  &__content {
    text-align: center;
  }

  &__item {
    margin-bottom: get-vw(30px);

    @include respond-max('tablet') {
      margin-bottom: get-vw(30px, $mob);
    }
  }

  &__label {
    font-size: get-vw(18px);
    font-weight: 400;
    line-height: get-vw(23px);
    color: #bfc0c4;
    margin-bottom: get-vw(10px);

    @include respond-max('tablet') {
      font-size: get-vw(18px, $mob);
      line-height: get-vw(23px, $mob);
      margin-bottom: get-vw(6px, $mob);
    }
  }

  &__value {
    font-family: $disket;
    font-size: get-vw(20px);
    font-weight: 700;
    line-height: get-vw(22px);
    color: #edeef1;

    @include respond-max('tablet') {
      font-size: get-vw(20px, $mob);
      line-height: get-vw(22px, $mob);
    }
  }

  &__progress {
    width: get-vw(246px);
    height: get-vw(3px);
    margin: 0 auto;
    border: get-vw(0.5px) solid #8b8b8b;
    border-radius: get-vw(3px);
    margin-top: get-vw(24px);

    @include respond-max('tablet') {
      width: get-vw(246px, $mob);
      height: get-vw(3px, $mob);
      border: get-vw(0.5px, $mob) solid #8b8b8b;
      border-radius: get-vw(3px, $mob);
      margin-top: get-vw(24px, $mob);
    }
  }

  &__progress-bar {
    height: 100%;
    background-color: #8b8b8b;
    border-radius: get-vw(3px);

    @include respond-max('tablet') {
      border-radius: get-vw(3px, $mob);
    }
  }

  &__lock-icon {
    position: relative;
    top: get-vw(10px);

    @include respond-max('tablet') {
      top: get-vw(10px, $mob);
    }
  }
}
