.catalog-desktop {
  width: 100%;
  height: 100%;
  min-height: get-vw(1080px);
  position: relative;
  overflow: hidden;

  @include respond-max('tablet') {
    display: none;
  }
}

.catalog-desktop_product_detail {
  min-height: get-vw(1000px);
}