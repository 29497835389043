.product-line {
  display: flex;
  position: relative;
  padding: get-vw(15px) 0;
  border-bottom: get-vw(0.5px) solid #7c7d7f;

  &:first-child {
    border-top: get-vw(0.5px) solid #7c7d7f;
  }

  @include respond-max('tablet') {
    flex-direction: column;
    position: relative;
    min-height: get-vw(235px, $mob);
    padding-left: get-vw(150px, $mob);
    padding-bottom: get-vw(30px, $mob);
    border: none;
    margin-bottom: 0;

    &:not(:last-child) {
      margin-bottom: get-vw(20px, $mob);
    }

    &:first-child {
      border: none;
    }
  }

  &__image {
    position: relative;
    width: get-vw(159px);
    height: get-vw(211px);

    @include respond-max('tablet') {
      width: get-vw(150px, $mob);
      height: get-vw(182px, $mob);
      position: absolute;
      left: 0;
      top: 0;
    }
  }

  &__image-svg {
    width: get-vw(163px);
    height: get-vw(213px);
    position: absolute;
    top: 0;
    left: get-vw(-5px);
    z-index: 1;

    @include respond-max('tablet') {
      width: get-vw(150px, $mob);
      height: get-vw(182px, $mob);
      left: 0;
    }

    svg {
      width: 100%;
      height: auto;
      display: block;
    }
  }

  &__image-mask {
    width: 100%;
    height: 100%;
    mask-image: url('../images/mask/order-image-mask.svg');
    mask-position: center;
    mask-size: 100%;

    img {
      width: 100%;
      height: 100%;
      display: block;
      object-fit: cover;
    }
  }

  &__content {
    flex: 1;
    padding-left: get-vw(40px);

    @include respond-max('tablet') {
      width: 100%;
      padding-left: get-vw(5px, $mob);
    }
  }

  &__style {
    color: #bfc0c4;

    @include respond-max('tablet') {
      margin-bottom: get-vw(5px, $mob);
    }
  }

  &__char {
    color: #BFC0C4;

    span {
      color: #edeef1;
      font-weight: 500;
    }

    @include respond-max('tablet') {
      font-size: get-vw(13px, $mob);
      margin-bottom: get-vw(5px, $mob);
    }
  }

  &__footer {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include respond-max('tablet') {
      gap: get-vw(5px, $mob);
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
    }
  }

  &__controls {
    margin-left: get-vw(-30px);

    .input-counter__button {
      border: none;
    }

    @include respond-max('tablet') {
      margin-left: 0
    }
  }

  &__price {
    width: get-vw(213px);
    height: get-vw(34px);
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url('../images/order/order-price-bg.svg');
    background-repeat: no-repeat;
    background-size: contain;

    @include respond-max('tablet') {
      width: get-vw(160px, $mob);
      height: get-vw(27px, $mob);
      font-size: get-vw(14px, $mob);
      background-image: url('../images/order/order-total-price-bg-m.svg');
    }
  }

  &__remove {
    position: absolute;
    top: get-vw(15px);
    right: 0;
    width: get-vw(36px);
    height: get-vw(32px);
    background: url('../images/order/order-remove.svg') no-repeat;
    background-size: 100%;
  }
}
