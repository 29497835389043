.catalog-mob-cards {
  display: flex;
  flex-direction: column;
  gap: get-vw(40px, $mob);
}

.catalog-mob-card {
  position: relative;

  &.is_active {
    .catalog-mob-card__header {
      background-image: url('../images/catalog/catalog-mob-card-active.svg');
    }

    .catalog-mob-card__picture {
      background-image: url('../images/catalog/catalog-mob-card-img-active.svg');
    }

    .catalog-mob-card__content {
      display: block;
    }
  }

  &:not(.is_active) {
    .catalog-mob-card__image {
      filter: invert(43%) sepia(21%) saturate(9%) hue-rotate(28deg)
        brightness(94%) contrast(80%);
    }
  }

  .catalog-product-swiper-wrapper {
    width: get-vw(288px, $mob);
    height: get-vw(360px, $mob);
  }

  .catalog-product-swiper-decor {
    width: get-vw(6px, $mob);
    height: get-vw(94px, $mob);
    top: get-vw(54px, $mob);
  }

  &__header {
    display: flex;
    height: get-vw(112px, $mob);
    background-image: url('../images/catalog/catalog-mob-card.svg');
    background-size: 100%;
    background-repeat: no-repeat;
  }

  &__picture {
    width: get-vw(112px, $mob);
    height: get-vw(112px, $mob);
    position: relative;
    background-image: url('../images/catalog/catalog-mob-card-img.svg');
    background-size: 100%;
    background-repeat: no-repeat;
  }

  &__image {
    width: 100%;
    height: 100%;
    position: absolute;
    inset: 0;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      display: block;
    }
  }

  &__short {
    flex: 1;
    padding: get-vw(8px, $mob) get-vw(15px, $mob) get-vw(8px, $mob)
      get-vw(10px, $mob);
  }

  &__title {
    padding-bottom: get-vw(5px, $mob);
    margin-bottom: get-vw(10px, $mob);
    border-bottom: get-vw(0.5px, $mob) solid #7c7d7f;
  }

  &__content-preview {
    height: get-vw(386px, $mob);
    padding: get-vw(15px, $mob) get-vw(15px, $mob);

    img,
    video {
      width: 100%;
      height: 100%;
      display: block;
      object-fit: contain;
    }

    .catalog-layer__image-fill img {
      object-fit: cover;
    }
  }

  &__content {
    display: none;
    border: get-vw(1px, $mob) solid #5E809F;
    border-top: none;
    padding-bottom: get-vw(35px, $mob);
    margin-top: get-vw(-4px, $mob);
    border-radius: 0 0 get-vw(5px, $mob) get-vw(5px, $mob);
  }

  &__content-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: get-vw(15px, $mob);
  }
}

.catalog-mob-card-char {
  display: flex;
  align-items: center;

  &:not(:last-child) {
    margin-bottom: get-vw(6px, $mob);
  }

  &__cell {
    font-size: get-vw(12px, $mob);
    color: #EDEEF1;
    
    &:first-child {
      width: 45%;
      color: #BFC0C4;
    }

    &:nth-child(2) {
      width: get-vw(40px, $mob);
      color: #BFC0C4;
    }

    &:nth-child(3) {
      text-align: center;
      flex: 1;
    }
  }
}

.catalog-color-circle {
  display: flex;
  justify-content: center;
  gap: get-vw(10px, $mob);

  &__icon {
    width: get-vw(6px, $mob);
    height: get-vw(6px, $mob);
    border-radius: 50%;
  }
}