.swiper-scrollbar {
  height: get-vw(4px);
  border: 1px solid #3d3d3e;
  border-radius: get-vw(4px);
  position: relative;

  @include respond-max('tablet') {
    height: get-vw(4px, $mob);
    border-radius: get-vw(4px, $mob);
  }
}

.swiper-scrollbar-drag {
  position: absolute;
  height: 100%;
  background: #8b8b8b;
  border-radius: get-vw(4px);

  @include respond-max('tablet') {
    border-radius: get-vw(4px, $mob);
  }
}
