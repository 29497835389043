.order-cart {
  @include respond-max('tablet') {
    height: auto;
    background-image: url('../images/order/order-cart-background-mob.svg');
    background-repeat: no-repeat;
    background-size: 100%;
    padding: get-vw(10px, $mob) get-vw(10px, $mob) get-vw(20px, $mob);
  }

  &__header-mob {
    @include respond-min('tablet') {
      display: none;
    }
  }

  &__header {
    display: flex;
    align-items: center;
    padding-bottom: get-vw(20px);
    border-bottom: get-vw(0.5px) solid #7c7d7f;

    @include respond-max('tablet') {
      display: none;
    }
  }

  &__header-title {
    width: get-vw(210px);
    color: #edeef1;
    font-weight: 500;
    padding-left: get-vw(30px);
  }

  &__header-label {
    min-width: get-vw(150px);
    text-align: center;

    &:last-child {
      min-width: get-vw(80px);
      text-align: right;
    }
  }

  &__body {
    height: get-vw(498px);
    padding-left: get-vw(10px);
    border-bottom: get-vw(1px) solid #7c7d7f;
    margin-bottom: get-vw(15px);

    .simplebar-track.simplebar-vertical {
      right: auto;
      left: get-vw(-30px);
      top: get-vw(15px);
      bottom: get-vw(15px);
    }

    @include respond-max('tablet') {
      padding-left: 0;
      padding-top: get-vw(20px, $mob);
      border: none;
      height: auto;
      margin-bottom: get-vw(30px, $mob);
    }
  }

  &__products {
    padding: get-vw(15px) 0;
  }

  &__footer {
    // padding-left: get-vw(40px);
    // padding-right: get-vw(86px);
    padding-bottom: get-vw(20px);
    border-bottom: get-vw(1px) solid #7c7d7f;
    margin-bottom: get-vw(20px);

    @include respond-max('tablet') {
      padding: get-vw(25px, $mob) 0;
      border-top: get-vw(1px, $mob) solid #7c7d7f;
      border-bottom: get-vw(1px, $mob) solid #7c7d7f;
      margin-bottom: get-vw(30px, $mob);
    }
  }

  &__row {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include respond-max('tablet') {
      margin-bottom: get-vw(5px, $mob);
    }
  }

  &__code-button {
    margin: get-vw(15px) 0 0 0;

    @include respond-max('tablet') {
      display: flex;
      justify-content: center;
      margin: get-vw(15px, $mob) 0 0;
    }
  }

  &__total {
    display: flex;
    align-items: center;
    justify-content: space-between;
    // padding-left: get-vw(40px);
    // padding-right: get-vw(20px);
    padding-bottom: get-vw(15px);

    @include respond-max('tablet') {
      // display: none;
      padding-left: 0;
      padding-right: 0;
      padding-bottom: get-vw(15px, $mob);
    }
  }

  &__total-price {
    width: get-vw(213px);
    height: get-vw(32px);
    display: flex;
    justify-content: center;
    align-items: center;
    background: url('../images/order/order-total-price-bg.svg') no-repeat;
    background-size: 100%;

    @include respond-max('tablet') {
      width: get-vw(170px, $mob);
      height: get-vw(27px, $mob);
      font-size: get-vw(20px, $mob);
      background: url('../images/order/order-total-price-bg-m.svg') no-repeat;
    }
  }
}
