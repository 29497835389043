.catalog-fabric {
  position: relative;

  &__svg {
    & > svg {
      width: 100%;
      height: auto;
      display: block;
      pointer-events: all;
      transform: translateZ(0);
    }

    circle[data-id] {
      offset-path: path(
        'M21.125,424.914a474.789,474.789 0 1,0 949.578,0a474.789,474.789 0 1,0 -949.578,0Z'
      );

      &:not(.is_active) {
        fill: none;
        fill: #242a31;
      }

      &.is_active {
        r: 12;
      }
    }
  }

  &__items {
    display: none;
    
    &.is_disabled {
      pointer-events: none;
    }
  }

  &__item {
    width: get-vw(140px);
    position: absolute;
    cursor: pointer;
    text-align: right;
    color: color('gray', 'm-light');
    transition: 0.3s ease-out;
    animation: fadeIn 1s ease-out forwards;

    &:hover {
      color: color('white', 'base');
    }

    &.is_active {
      pointer-events: none;
      // opacity: 0 !important;
      // color: color('blue', 'sky');
      font-size: get-vw(20px);
      font-weight: 700;
      color: #EDEEF1;
    }

    &.is_hidden {
      opacity: 0 !important;
      pointer-events: none;
      animation: fadeOut 0.5s ease-out;
    }

    &.is_disabled {
      color: color('red', 'dark');
      pointer-events: none;
    }

    &[data-disabled='1'] {
      // color: color('red', 'dark');
      pointer-events: none;
      opacity: 0 !important;

      &:after {
        content: '';
        content: '';
          position: absolute;
          top: get-vw(-1px);
          right: get-vw(-42px);
          width: get-vw(14px);
          height: get-vw(17px);
          background-image: url('../images/icons/lock.svg');
          background-repeat: no-repeat;
          background-size: 100%;
      }
    }

    &:first-child {
      top: 6.3%;
      left: 15%;
    }

    &:nth-child(2) {
      top: 19.5%;
      left: -9%;
    }

    &:nth-child(3) {
      top: 37.8%;
      left: -35%;
    }

    &:nth-child(4) {
      top: 57.5%;
      left: -38%;
    }

    &:nth-child(5) {
      top: 77%;
      left: -37%;
    }
  }

  &__content {
    width: get-vw(242px);
    position: absolute;
    top: 20%;
    // left: 42%;
    // right: 0;
    right: get-vw(-50px);
    text-align: center;
  }

  &__content-item {
    position: relative;
  }

  &__content-icon {
    width: get-vw(72px);
    height: get-vw(72px);
    position: absolute;
    left: get-vw(-105px);
    top: get-vw(-15px);
    border-radius: get-vw(20px);
    border: 1px solid color('white', 'base');

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}
