.order-group-wrapper {
  @include respond-max('tablet') {
    &.is_opened {
      .order-group-header {
        .icon-plus-add {
          opacity: 0;
        }

        .icon-minus-add {
          opacity: 1;
        }
      }

      .order-group-body {
        display: block;
      }
    }
  }
}

.order-group-header {
  color: #000;
  cursor: pointer;
  background-color: #cae858;
  display: flex;
  align-items: center;
  gap: get-vw(8px, $mob);
  padding: get-vw(6px, $mob) get-vw(10px, $mob);

  @include respond-min('tablet') {
    display: none;
  }

  &__icon {
    width: get-vw(8px, $mob);
    height: get-vw(8px, $mob);
    position: relative;

    svg {
      @include abscenter();
    }
  }

  .icon-plus-add {
    width: get-vw(8px, $mob);
    height: get-vw(8px, $mob);
  }

  .icon-minus-add {
    width: get-vw(8px, $mob);
    height: get-vw(2px, $mob);
    opacity: 0;
  }
}

.order-group-body {
  @include respond-max('tablet') {
    display: none;
    padding-top: get-vw(50px, $mob);
  }
}
