.personal-box {
  position: relative;
  width: get-vw(1569px);
  height: get-vw(826px);
  padding-left: get-vw(95px);
  padding-right: get-vw(460px);
  padding-top: get-vw(70px);
  background: url('../images/personal/personal-screen.svg') no-repeat;
  background-size: 100%;
  margin-left: auto;
  margin-right: get-vw(35px);

  .password-hard {
    max-width: get-vw(375px);

    @include respond-max('tablet') {
      max-width: 100%;
    }
  }

  &__infomenu {
    position: absolute;
    top: get-vw(50px);
    right: 0;
    width: get-vw(380px);
  }

  @include respond-max('tablet') {
    width: get-vw(340px, $mob);
    height: auto;
    background-color: #0e0e0f;
    background-image: url('../images/personal/personal-bg-mob.svg');
    background-repeat: no-repeat;
    padding: get-vw(54px, $mob) get-vw(10px, $mob) get-vw(40px, $mob);
    z-index: 1;

    &__infomenu {
      display: none;
    }
  }
}

.personal-box_wide {
  @include respond-min('tablet') {
    padding-right: get-vw(60px);
  }
}

.personal-header {
  display: flex;
  position: relative;
  padding-bottom: get-vw(40px);
  // margin-bottom: get-vw(20px);

  @include respond-max('tablet') {
    display: block;
    padding-bottom: get-vw(20px, $mob);
    margin-bottom: get-vw(20px, $mob);
  }

  &:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: get-vw(70px);
    right: 0;
    height: get-vw(1px);
    // background: #7c7d7f;

    @include respond-max('tablet') {
      left: 0;
      height: get-vw(1px, $mob);
    }
  }

  &__logo {
    width: get-vw(70px);

    @include respond-max('tablet') {
      display: none;
    }
  }

  &__title {
    flex: 1;
    position: relative;
    font-size: get-vw(32px);
    color: #bfc0c4;
    letter-spacing: 1.01em;

    &.is_active {
      .icon-pager-prev {
        transform: rotate(-90deg);
      }
    }

    .icon-pager-prev {
      width: get-vw(9px, $mob);
      height: get-vw(17px, $mob);
      color: #5e809f;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      margin: auto;
    }

    @include respond-min('tablet') {
      pointer-events: none;

      .icon-pager-prev {
        display: none;
      }
    }

    @include respond-max('tablet') {
      font-size: get-vw(25px, $mob);
      letter-spacing: 0;
    }
  }

  &__menu {
    @include respond-min('tablet') {
      position: absolute;
      top: get-vw(80px);
      left: get-vw(-345px);
    }

    @include respond-max('tablet') {
      display: none;
    }
  }
}

.personal-body {
  position: relative;

  &:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: get-vw(70px);
    right: 0;
    height: get-vw(1px);
    // background: #7c7d7f;

    @include respond-max('tablet') {
      display: none;
    }
  }

  &__scrollbar {
    @include respond-min('tablet') {
      height: get-vw(568px);
      padding-left: get-vw(70px);
      // padding-top: get-vw(50px);
      padding-bottom: get-vw(50px);

      .simplebar-track.simplebar-vertical {
        left: auto;
        right: get-vw(-50px);
        bottom: 0;
      }
    }
  }
}

.personal-row {
  display: flex;

  @include respond-max('tablet') {
    flex-direction: column;
  }

  &__label {
    width: get-vw(214px);
    padding-top: get-vw(15px);

    @include respond-max('tablet') {
      width: 100%;
      padding: 0;
      margin-bottom: get-vw(10px, $mob);
    }
  }

  &__title {
    color: #bfc0c4;
    font-size: get-vw(16px);
    font-weight: 600;
    line-height: get-vw(20px);

    @include respond-max('tablet') {
      font-size: get-vw(14px, $mob);
      line-height: get-vw(20px, $mob);
    }
  }

  &__field {
    flex: 1;
  }
}

.personal-footer {
  padding-top: get-vw(42px);
  display: flex;
  justify-content: flex-end;

  @include respond-min('tablet') {
    position: absolute;
    bottom: get-vw(80px);
    right: get-vw(35px);
  }

  @include respond-max('tablet') {
    justify-content: center;
  }
}

.personal-menu {
  @include respond-max('tablet') {
    padding-top: get-vw(30px, $mob);
  }

  &__header {
    width: get-vw(380px);
    height: get-vw(58px);
    display: flex;
    align-items: center;
    justify-content: center;
    background: url('../images/personal/personal-infomenu-header.svg') no-repeat;
    background-size: 100%;
    margin-bottom: get-vw(50px);

    @include respond-max('tablet') {
      display: none;
    }
  }

  &__title {
    font-size: get-vw(24px);
    font-weight: 700;
    line-height: get-vw(31px);
    letter-spacing: 0.05em;
    text-transform: uppercase;

    @include respond-max('tablet') {
      font-size: get-vw(24px, $mob);
      line-height: get-vw(31px, $mob);
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
    gap: get-vw(20px);

    @include respond-max('tablet') {
      align-items: center;
      gap: get-vw(10px, $mob);
    }
  }

  &__link {
    width: get-vw(215px);
    height: get-vw(45px);
    color: #5e809f;
    display: flex;
    align-items: center;
    padding: 0 get-vw(15px) get-vw(5px) get-vw(22px);
    font-size: get-vw(18px);
    background-image: url('../images/personal/personal-menu.svg');
    background-repeat: no-repeat;
    background-size: 100%;
    transition: 0.3s ease-out;

    @include respond-max('tablet') {
      width: get-vw(215px, $mob);
      height: get-vw(45px, $mob);
      padding: 0 get-vw(10px, $mob) get-vw(5px, $mob) get-vw(22px, $mob);
      font-size: get-vw(18px, $mob);
    }

    &:hover {
      color: #fff;
    }

    &.is_active {
      color: #fff;
      font-weight: 600;
      background-image: url('../images/personal/personal-menu-active.svg');
    }
  }
}

.personal-accordion {
  ul li {
    list-style: disc;
    list-style-position: inside;
  }
}
