.catalog-cards {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: get-vw(30px);
  // padding-left: get-vw(50px);

  @include respond-max('tablet') {
    grid-template-columns: 1fr 1fr;
    gap: get-vw(36px, $mob);
    padding-left: 0;
  }
}

.catalog-card {
  position: relative;
  cursor: pointer;

  @include respond-max('tablet') {
    &:nth-child(2n) {
      .catalog-card__title {
        text-align: right;
      }
    }
  }

  &:hover,
  &.is_active {
    .catalog-card__layer {
      background-image: url('../images/catalog/catalog-card-active.svg');
    }
  }

  &:not(.is_active) {
    .catalog-card__image {
      filter: invert(43%) sepia(21%) saturate(9%) hue-rotate(28deg)
        brightness(94%) contrast(80%);
    }
  }

  &__layer {
    position: relative;
    padding-bottom: 100%;
    background-image: url('../images/catalog/catalog-card.svg');
    background-size: 100%;
    background-repeat: no-repeat;
  }

  &__image {
    width: 100%;
    height: 100%;
    position: absolute;
    inset: 0;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      display: block;
    }
  }
}
