@font-face {
  font-family: 'Disket';
  src: url('../fonts/DisketMono.eot');
  src: url('../fonts/DisketMono.eot?#iefix')format('embedded-opentype'),
    url('../fonts/DisketMono.woff2') format('woff2'),
    url('../fonts/DisketMono.woff') format('woff'),
    url('../fonts/DisketMono.ttf') format('truetype');
  font-style: normal;
  font-weight: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Disket';
  src: url('../fonts/DisketMono-Bold.eot');
  src: url('../fonts/DisketMono-Bold.eot?#iefix')format('embedded-opentype'),
    url('../fonts/DisketMono-Bold.woff2') format('woff2'),
    url('../fonts/DisketMono-Bold.woff') format('woff'),
    url('../fonts/DisketMono-Bold.ttf') format('truetype');
  font-style: normal;
  font-weight: 700;
  font-display: swap;
}

