.home-page {
  overflow: hidden;
  height: get-vw(1080px);
  padding-top: get-vw(128px);
  background-image: url('../images/home/home-bg.svg');
  background-size: 100%;
  background-repeat: no-repeat;

  @include respond-max('tablet') {
    padding-top: 0;
    height: get-vw(780px, $mob);
    background-image: none;
  }
}

.home-page-container {
  width: get-vw(1824px);
  height: get-vw(850px);
  margin: 0 auto;
  position: relative;

  @include respond-max('tablet') {
    width: get-vw(336px, $mob);
    height: get-vw(660px, $mob);
  }
}

.home-page-mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;

  svg {
    width: 100%;
    height: auto;
    display: block;
  }

  &__image {
    transition: 0.6s ease;

    @include respond-min('tablet') {
      &.is_hide {
        transform: scale(1.04);
        opacity: 0;
      }
    }

    @include respond-max('tablet') {
      &.is_hide {
        opacity: 0;
      }
    }
  }
}

.home-page-title {
  width: get-vw(260px);
  position: absolute;
  top: get-vw(30px);
  left: 0;
  text-align: center;
  color: #bfc0c4;

  @include respond-max('tablet') {
    top: get-vw(100px, $mob);
    left: auto;
    right: get-vw(-16px, $mob);
    font-size: get-vw(9px, $mob);
    transform: rotate(-90deg);
  }
}

.home-page-news {
  position: absolute;
  bottom: get-vw(60px);
  right: get-vw(70px);
  z-index: 2;
  display: flex;
  gap: get-vw(20px);

  @include respond-max('tablet') {
    flex-direction: column;
    right: auto;
    left: get-vw(18px, $mob);
    bottom: get-vw(-80px, $mob);
    gap: get-vw(10px, $mob);
  }
}
