.order-layer {
  position: relative;

  .simplebar-track.simplebar-horizontal {
    display: none;
  }

  @include respond-min('tablet') {
    padding: get-vw(50px) get-vw(40px) get-vw(20px) get-vw(70px);
    width: get-vw(1818px);
    height: get-vw(891px);
    margin: 0 auto;
  }

  @include respond-max('tablet') {
    padding: 0 get-vw(10px, $mob);
  }

  &__title {
    @include respond-min('tablet') {
      display: none;
    }
  }

  &__subtitle {
    position: absolute;
    top: get-vw(10px, $mob);
    left: get-vw(120px, $mob);
    right: 0;
    color: #9b9b9b;

    @include respond-min('tablet') {
      display: none;
    }
  }

  &__decor-top {
    @include respond-min('tablet') {
      display: none;
    }

    @include respond-max('tablet') {
      img {
        display: block;
        width: 100%;
      }
    }
  }

  &__decor-bottom {
    @include respond-min('tablet') {
      display: none;
    }

    @include respond-max('tablet') {
      img {
        display: block;
        width: 100%;
      }
    }
  }

  &__background {
    position: absolute;
    inset: 0;

    img {
      display: block;
      width: 100%;
    }

    @include respond-max('tablet') {
      display: none;
    }
  }

  &__box {
    position: relative;

    @include respond-min('tablet') {
      display: flex;
    }

    @include respond-max('tablet') {
      padding: 0 get-vw(10px, $mob);
      border: get-vw(1px, $mob) solid rgba(60, 60, 62, 0.68);
      border-top: none;
      border-bottom: none;
      background-color: #101113;
    }
  }

  &__left {
    @include respond-min('tablet') {
      width: get-vw(750px);
    }

    @include respond-max('tablet') {
      display: none;
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 10;
      overflow: auto;
      padding: get-vw(84px, $mob) get-vw(10px, $mob) get-vw(10px, $mob);
      background-color: #000;

      &.is_visible {
        display: block;
      }
    }
  }

  &__right {
    @include respond-min('tablet') {
      width: get-vw(735px);
      margin: 0 auto;
    }

    @include respond-max('tablet') {
      padding-top: get-vw(30px, $mob);
    }
  }

  &__sections {
    @include respond-min('tablet') {
      max-height: get-vw(720px);

      .simplebar-track.simplebar-vertical {
        right: get-vw(-80px);
        top: 0;
        bottom: 0;
      }
    }
  }

  &__submit {
    @include respond-max('tablet') {
      display: flex;
      justify-content: center;
      margin-top: get-vw(50px, $mob);
    }
  }

  &__products {
    @include respond-max('tablet') {
      margin-bottom: get-vw(50px, $mob);
    }
  }

  &__products-marketplace {
    @include respond-max('tablet') {
      margin-bottom: get-vw(30px, $mob);

      .arrow-link {
        color: #fff;
      }
    }
  }

  &__products-slider {
    @include respond-max('tablet') {
      margin-left: -10px;
      margin-right: -10px;
    }
  }
}

.order-layer-header-mob {
  display: none;

  @include respond-max('tablet') {
    display: block;
    position: absolute;
    top: get-vw(-36px, $mob);
    left: get-vw(53px, $mob);
    right: get-vw(18px, $mob);
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__count {
    .input-counter__input {
      width: get-vw(44px, $mob);
      height: get-vw(44px, $mob);
      font-size: get-vw(20px, $mob);
      border: get-vw(1px, $mob) solid #3d3d3e;
    }
  }
}
