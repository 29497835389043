.page404 {
  width: 100%;
  height: 100%;
  position: relative;

  @include respond-max('tablet') {
    margin-top: get-vw(-82px, $mob);
  }

  &__image {
    width: 100%;

    img {
      display: block;
      width: 100%;
    }
  }

  &__image_desktop {
    @include respond-max('tablet') {
      display: none;
    }
  }

  &__image_mob {
    @include respond-min('tablet') {
      display: none;
    }
  }

  &__wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
  }

  &__block {
    width: get-vw(432px);
    margin: auto;
    padding-top: get-vw(170px);

    @include respond-max('tablet') {
      width: get-vw(204px, $mob);
      left: 0;
      top: get-vw(370px, $mob);
    }
  }

  &__label {
    color: #558b97;
    font-family: $montserrat;
    font-size: get-vw(36px);
    line-height: 1;
    font-weight: 700;
    letter-spacing: 0.77em;
    text-align: right;

    @include respond-max('tablet') {
      font-size: get-vw(16px, $mob);
    }
  }

  &__title {
    font-size: get-vw(240px);
    line-height: 0.8;
    font-weight: 500;
    text-align: left;
    color: #f7f7f7;
    margin-bottom: get-vw(40px);

    @include respond-max('tablet') {
      font-size: get-vw(113px, $mob);
      margin-bottom: get-vw(40px, $mob);
    }
  }

  &__middle {
    display: flex;
    justify-content: space-between;
    margin-bottom: get-vw(20px);

    @include respond-max('tablet') {
      margin-bottom: get-vw(20px, $mob);
    }
  }

  &__middle-label {
    font-size: get-ve(17px);
    font-weight: 500;
    line-height: 1;
    color: #3d3d3d;

    @include respond-max('tablet') {
      font-size: get-vw(8px, $mob)
    }
  }

  &__middle-text {
    width: get-vw(190px);
    font-size: get-vw(11px);
    font-weight: 300;

    @include respond-max('tablet') {
      width: get-vw(90px, $mob);
      font-size: get-vw(6px, $mob);
    }
  }

  &__button {
    display: flex;
    justify-content: flex-end;
  }
}
