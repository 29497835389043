.personal-archive {
  &__row {
    display: flex;
    font-size: get-vw(20px);
    line-height: get-vw(26px);

    @include respond-max('tablet') {
      font-size: get-vw(16px, $mob);
      line-height: get-vw(20px, $mob);
      flex-direction: column;
    }

    &:not(:last-child) {
      margin-bottom: get-vw(20px);

      @include respond-max('tablet') {
        margin-bottom: get-vw(20px, $mob);
      }
    }
  }

  &__label {
    width: get-vw(320px);
    font-weight: 300;
    color: #7c7d7f;

    @include respond-max('tablet') {
      width: 100%;
    }
  }

  &__value {
    font-weight: 400;
    text-align: left;
    color: #edeef1;
  }
}

.personal-archive-buttons {
  display: flex;
  justify-content: space-around;
  margin-top: get-vw(40px);

  @include respond-max('tablet') {
    margin-top: get-vw(40px, $mob);
    flex-direction: column;
    gap: get-vw(20px, $mob)
  }
}

.personal-archive-button {
  width: get-vw(302px);
  height: get-vw(46px);
  font-family: $montserrat;
  font-size: get-vw(19px);
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #e0e0e0;
  background: url('../images/personal/personal-archive-button.svg') no-repeat;
  background-size: 100%;

  @include respond-max('tablet') {
    width: get-vw(302px, $mob);
    height: get-vw(46px, $mob);
    font-size: get-vw(19px, $mob);
  }
}

.personal-archive-button_green {
  color: #0f1014;
  background: url('../images/personal/personal-archive-button-green.svg')
    no-repeat;
  background-size: 100%;
}
