.catalog-point {
  offset-distance: 91.8%;
  offset-path: path(
    'M32.4175 349.927C15.963 422.939 33.5748 500.206 79.5867 577.44C125.601 654.677 200.002 731.854 297.067 804.65C491.196 950.241 775.898 1078.25 1105.16 1154.14C1434.42 1230.02 1745.86 1239.42 1983.2 1193.27C2101.87 1170.2 2201.99 1133.24 2276.56 1083.8C2351.12 1034.35 2400.1 972.434 2416.55 899.422C2433.01 826.411 2415.39 749.144 2369.38 671.91C2323.37 594.673 2248.97 517.495 2151.9 444.699C1957.77 299.109 1673.07 171.102 1343.81 95.2131C1014.54 19.3245 703.11 9.93401 465.77 56.0799C347.1 79.1531 246.977 116.106 172.413 165.552C97.8517 214.997 48.872 276.915 32.4175 349.927Z'
  );

  &:first-child {
    offset-distance: 91.8%;
  }

  &:nth-child(2) {
    offset-distance: 94.32%;
  }

  &:nth-child(3) {
    offset-distance: 97.9%;
  }
}
