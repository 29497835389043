.auth-aside {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 11;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
    linear-gradient(90deg, #737682 0%, rgba(0, 0, 0, 0) 100%),
    linear-gradient(0deg, rgba(128, 148, 155, 0.2), rgba(128, 148, 155, 0.2));
  transition: 0.8s cubic-bezier(0.165, 0.84, 0.44, 1);
  overflow: auto;
  opacity: 0;
  pointer-events: none;

  @include respond-max('tablet') {
    background: #141414;
    padding: get-vw(84px, $mob) get-vw(10px, $mob) get-vw(10px, $mob);
    z-index: 5;
  }

  .personal-infomenu__header {
    width: get-vw(350px);
    height: get-vw(54px);

    @include respond-max('tablet') {
      width: get-vw(320px, $mob);
      height: get-vw(52px, $mob);
    }
  }

  &.is_opened {
    opacity: 1;
    pointer-events: all;

    @include respond-min('tablet') {
      &:before,
      .auth-aside__box {
        transform: translate3d(0, 0, 0);
        transition-delay: 0.3s;
      }
    }

    @include respond-max('tablet') {
      &:before,
      .auth-aside__box {
        transform: translate3d(0, 0, 0);
      }
    }
  }

  &:before {
    content: '';
    position: absolute;
    width: get-vw(422px);
    top: 0;
    bottom: 0;
    right: 0;
    box-shadow: get-vw(-100px) 0 get-vw(200px) rgba(255, 255, 255, 0.05);
    transition: 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
    transform: translate3d(100%, 0, 0);

    @include respond-max('tablet') {
      display: none;
    }
  }

  &__overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &__box {
    margin-left: auto;
    background-image: url('../images/cart/cart-background.svg');
    background-repeat: no-repeat;
    background-size: 100%;
    width: get-vw(462px);
    height: get-vw(1080px);
    position: relative;
    z-index: 2;
    padding: get-vw(33px) get-vw(56px);
    transition: 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
    transform: translate3d(100%, 0, 0);

    @include respond-max('tablet') {
      transform: translate3d(0, 20px, 0);
      width: 100%;
      height: get-vw(1011px, $mob);
      background-image: url('../images/cart/cart-background-mob.svg');
      padding: get-vw(10px, $mob) get-vw(10px, $mob) get-vw(30px, $mob);
    }
  }

  &__header {
    position: relative;
    color: #7c7d7f;
    border-bottom: get-vw(1px) solid #7c7d7f;
    padding-bottom: get-vw(30px);
    margin-bottom: get-vw(35px);

    @include respond-max('tablet') {
      border-bottom: get-vw(1px, $mob) solid #7c7d7f;
      padding-bottom: get-vw(20px, $mob);
      margin-bottom: get-vw(30px, $mob);

      &:before {
        content: '';
        position: absolute;
        bottom: get-vw(-20px, $mob);
        right: 0;
        width: get-vw(75px, $mob);
        height: get-vw(34px, $mob);
        background: url('../images/cart/cart-aside-decor.svg') no-repeat;
        background-size: 100%;
      }
    }

    .icon-cart-arrow-rb {
      width: get-vw(8px);
      height: get-vw(8px);

      @include respond-max('tablet') {
        width: get-vw(8px, $mob);
        height: get-vw(8px, $mob);
      }
    }
  }

  &__close {
    position: absolute;
    top: 0;
    right: 0;
    width: get-vw(65px);
    height: get-vw(58px);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background: url('../images/icons/close-border-wrapper.svg') no-repeat;
    background-size: 100%;

    @include respond-max('tablet') {
      width: get-vw(30px, $mob);
      height: get-vw(27px, $mob);
    }

    @media (hover: hover) {
      &:hover {
        .icon-burger-close {
          color: #fff;
          transform: rotate(180deg);
        }
      }
    }

    .icon-burger-close {
      width: get-vw(15px);
      height: get-vw(15px);
      transition: 0.8s cubic-bezier(0.165, 0.84, 0.44, 1);

      @include respond-max('tablet') {
        width: get-vw(8px, $mob);
        height: get-vw(8px, $mob);
      }
    }
  }

  &__title {
    text-transform: uppercase;
    padding-right: get-vw(100px);

    @include respond-max('tablet') {
      max-width: get-vw(140px, $mob);
      font-size: get-vw(25px, $mob);
    }
  }

  &__body {
    height: 100%;
    // max-height: get-vw(500px);
    height: get-vw(500px);
    border-bottom: get-vw(1px) solid #7c7d7f;

    @include respond-max('tablet') {
      max-height: inherit;
      height: auto;
      margin-bottom: 0;
      border-bottom: get-vw(1px, $mob) solid #7c7d7f;
    }

    .simplebar-track.simplebar-vertical {
      right: auto;
      left: get-vw(-35px);
      bottom: get-vw(110px);

      @include respond-max('tablet') {
        display: none;
      }
    }
  }

  &__button {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    margin-top: get-vw(40px);
    margin-bottom: get-vw(40px);

    @include respond-max('tablet') {
      margin-top: get-vw(30px, $mob);
      margin-bottom: get-vw(40px, $mob);
    }
  }

  &__footer {
    padding-top: get-vw(10px);
    position: relative;
    z-index: 2;

    @include respond-max('tablet') {
      padding-top: get-vw(40px, $mob);
    }
  }

  &__footer-buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: get-vw(15px);

    @include respond-max('tablet') {
      gap: get-vw(15px, $mob);
    }
  }

  &__hint {
    width: 100%;
    text-align: center;
    background: #3d3d3e;
    padding: get-vw(12px);
    font-size: get-vw(12px);
    color: #bfc0c4;
    border-radius: get-vw(3px);

    @include respond-max('tablet') {
      padding: get-vw(12px, $mob);
      font-size: get-vw(12px, $mob);
      border-radius: get-vw(3px, $mob);
    }
  }

  &__informer {
    border-top: get-vw(1px) solid #7c7d7f;
    padding-top: get-vw(30px);

    @include respond-max('tablet') {
      border-top: get-vw(1px, $mob) solid #7c7d7f;
      padding-top: get-vw(30px, $mob);
    }
  }
}

.auth-decor-header {
  position: relative;
  margin-bottom: get-vw(40px);

  @include respond-max('tablet') {
    margin-bottom: get-vw(30px, $mob);
  }

  &__icon {
    @include respond-max('tablet') {
      img {
        width: get-vw(30px, $mob);
      }
    }
  }

  &__title {
    color: #47565e;

    @include respond-max('tablet') {
      font-size: get-vw(15px, $mob);
    }
  }

  &__text {
    width: get-vw(167px);
    position: absolute;
    bottom: 0;
    right: 0;
    font-size: get-vw(10px);
    color: #bfc0c4;
    font-weight: 300;
    text-transform: uppercase;
  }
}

.auth-aside-login-buttons {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  gap: get-vw(20px);
  z-index: 2;

  @include respond-max('tablet') {
    gap: get-vw(10px, $mob);
  }
}

.auth-aside-login-button {
  width: get-vw(215px);
  height: get-vw(43px);
  display: flex;
  align-items: center;
  padding: 0 get-vw(20px);
  cursor: pointer;
  font-size: get-vw(20px);
  color: #5e809f;
  background-image: url('../images/auth/login-button.svg');
  background-repeat: no-repeat;
  background-size: 100%;

  @include respond-max('tablet') {
    width: get-vw(185px, $mob);
    height: get-vw(36px, $mob);
    padding: 0 get-vw(15px, $mob);
    font-size: get-vw(15px, $mob);
  }

  &.is_active {
    color: #fff;
    font-weight: 700;
    background-image: url('../images/auth/login-button-active.svg');
  }
}

.auth-form-fields {
  .form-checkbox {
    a {
      color: #5E809F;
    }
  }
}