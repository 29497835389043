.form-phone-code {
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: get-vw(30px);

    @include respond-max('tablet') {
      margin-bottom: get-vw(30px, $mob);
    }
  }
}