$ibm: 'IBM Plex Mono', monospace;
$montserrat: 'Montserrat', sans-serif;
$rajdhani: 'Rajdhani', sans-serif;
$disket: 'Disket', sans-serif;

html,
body {
  height: 100%;
}

body {
  position: relative;
  font-size: get-vw(14px);
  font-family: $ibm;
  color: color('white', 'base');
  background-color: color('black', 'base');
  -webkit-font-smoothing: antialiased;

  @include respond-max('tablet') {
    font-size: get-vw(14px, $mob);
    padding-top: get-vw(82px, $mob);
  }
}

*,
:before,
:after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

* {
  -webkit-locale: auto;
}

svg {
  pointer-events: none;
}

input,
textarea,
button {
  border: none;
  outline: none;
  border-radius: 0;
}

input[type='text'],
input[type='password'],
input[type='tel'],
input[type='email'],
input[type='number'],
textarea,
button {
  -webkit-appearance: none;
}

input::-webkit-credentials-auto-fill-button,
input::-webkit-contacts-auto-fill-button {
  visibility: hidden;
  pointer-events: none;
  position: absolute;
  right: 0;
}

input::-ms-reveal,
input::-ms-clear {
  width: 0;
  height: 0;
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 50px #0e0e0f inset; /* Change the color to your own background color */
  -webkit-text-fill-color: #fff;
}

input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0 50px #0e0e0f inset; /*your box-shadow*/
  -webkit-text-fill-color: #fff;
}

fieldset {
  border: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: inherit;
  font-weight: normal;
  color: inherit;
}

a {
  color: inherit;
  text-decoration: none;

  &:hover,
  &:active,
  &:focus {
    text-decoration: none;
  }
}

li {
  list-style: none;
}

.visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  width: 1px;
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
}

[data-simplebar] {
  max-height: 100%;
}

.preloader {
  transition: 1.5s ease-out;
  background: linear-gradient(104deg, #141414, #2b2b2b, #2e3038);
  background-size: 600% 600%;
  animation: gradientAnimation 30s ease infinite;
}

.preloader_hide {
  opacity: 0;
}

.body-lock {
  overflow: hidden;
}

@keyframes gradientAnimation {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

.animation-move {
  animation: animationMov 10s ease infinite;
}

@keyframes animationMov {
  0% {
    transform: translate3d(0, 4px, 0);
  }

  30% {
    transform: translate3d(5px, -7px, 0);
  }

  70% {
    transform: translate3d(-8px, 7px, 0);
  }

  100% {
    transform: translate3d(0, 4px, 0);
  }
}

.inform-block {
  width: get-vw(300px);
  height: get-vw(46px);
  background: url('../images/inform-block.svg') no-repeat;
  background-size: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: get-vw(18px);
  color: #f24040;
  font-family: $montserrat;

  @include respond-max('tablet') {
    width: get-vw(300px, $mob);
    height: get-vw(46px, $mob);
    font-size: get-vw(18px, $mob);
  }
}

.page-loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(#18191b, 0.8);
  z-index: 1000;
  backdrop-filter: blur(5px);
  display: none;

  &.is_visible {
    display: block;
  }

  .preloader-circle {
    opacity: 1;
  }
}

.js-datepicker {
  cursor: pointer;
}

.page-wrapper {
  overflow-x: hidden;
}