/**
 * Variables
 */

 @import 'settings/colors';
 @import 'settings/breakpoints';

 /**
  * Functions, mixins
  */

 @import 'tools/functions';
 @import 'tools/mixins';

/**
 * Basic rules
 */

@import 'base/base';
@import 'base/fonts';

/**
 * Vendor
 */

@import 'vendor/import';

/**
 * Components
 */

@import 'components/typography';
@import 'components/atom';
@import 'components/button';
@import 'components/container';
@import 'components/header';
@import 'components/menu';
@import 'components/footer';
@import 'components/form';
@import 'components/form-phone-code';
@import 'components/filter-checkbox';
@import 'components/tab-control';
@import 'components/input-counter';
@import 'components/cart/index';
@import 'components/product/index';
@import 'components/swiper-overides';
@import 'components/pagination';
@import 'components/preloader-circle';
@import 'components/auth-aside';
@import 'components/accordion';
@import 'components/delivery-radio';
@import 'components/aside-panel';
@import 'components/map';
@import 'components/autocomplete';
@import 'components/password-hard';
@import 'components/password-ui';

/**
 * Pages
 */

@import 'pages/index';
