.catalog-back-menu {
  cursor: pointer;
  width: get-vw(169px);
  height: get-vw(34px);
  position: absolute;
  // bottom: get-vw(40px);
  // bottom: get-vw(20px);
  top: get-vw(65px);
  left: get-vw(100px);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: get-vw(14px);
  background: url('../images/button-back.svg') no-repeat;
  background-size: 100%;
  z-index: 7;

  @media (hover:hover) {
    transition: .3s ease-out;

    &:hover {
      transform: scale(1.05);
    }
  }

  .icon-arrow-left {
    width: get-vw(6px);
    height: get-vw(11px);
  }

  &__title {
    font-size: get-vw(16px);
    font-weight: 500;
    line-height: get-vw(16px);
    letter-spacing: 0em;
  }
}
