.map {
  width: 100%;
  height: 100%;

  &__wrapper,
  &__container {
    width: 100%;
    height: 100%;
  }

  [class*='-map-copyrights-promo'],
  [class*='-copyright'] {
    display: none !important;
  }

  [class*='-image-with-content-content'] {
    position: absolute;
    width: 100% !important;
    height: 100% !important;
    z-index: 1;

    * {
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
    }
  }
}

.place-mark {
  background-size: cover;
  background-position: center;

  &__hover {
    opacity: 0;
    background-size: cover;
    background-position: center;
  }

  &__active {
    opacity: 0;
    background-size: cover;
    background-position: center;
  }
}

.place-mark_hover {
  .place-mark__hover {
    opacity: 1;
  }
}

.place-mark_active {
  .place-mark__active {
    opacity: 1;
  }
}

.map-cluster {
  color: var(--color);
  background-size: cover;
  background-position: center;

  &__content {
    position: relative;
    z-index: 2;
  }

  &__bg-hover {
    opacity: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background-size: cover;
    background-position: center;
  }

  &__bg-active {
    opacity: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background-size: cover;
    background-position: center;
  }

  &_hover {
    color: var(--hover-color);

    .map-cluster__bg-hover {
      opacity: 1;
    }
  }
}

.map-cluster_active {
  transform-origin: center;
  transform: scale(1.3333);

  .map-cluster__bg-active {
    opacity: 1;
  }
}

