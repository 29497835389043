.catalog-rare-mob {
  &__item {
    cursor: pointer;
    width: get-vw(36px, $mob);
    height: get-vw(36px, $mob);
    position: absolute;
    top: get-vw(20px, $mob);
    left: 0;
    offset-rotate: 0deg;
    transition: 0.5s transform;
    offset-distance: 75%;
    offset-path: path(
      'M0,279.486a279.486,279.486 0 1,0 558.972,0a279.486,279.486 0 1,0 -558.972,0Z'
    );
    will-change: offset-distance;

    &:first-child {
      offset-distance: 65.5%;
    }

    &:nth-child(2) {
      offset-distance: 69.5%;
    }

    &:nth-child(3) {
      offset-distance: 75%;
    }

    &:nth-child(4) {
      offset-distance: 80.2%;
    }

    &:nth-child(5) {
      offset-distance: 84.5%;
    }

    &:not(.is_active) {
      transform: scale(0.7);
    }

    &[data-disabled='1'] {
      pointer-events: none;

      img {
        opacity: 0;
      }

      &:before {
        content: '';
        width: get-vw(16px, $mob);
        height: get-vw(16px, $mob);
        border-radius: 50%;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        background-color: #090A10;
        border: 1px solid #7c7d7f;
      }
    }

    &.is_active {
      .catalog-rare-mob__icon-active {
        opacity: 1;
      }
    }
  }

  &__icon-default {
    width: 100%;
    height: 100%;
    display: block;
  }

  &__icon-active {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    opacity: 0;
  }
}

.catalog-rare-mob-button {
  width: get-vw(22px, $mob);
  height: get-vw(22px, $mob);
  position: absolute;
  top: get-vw(132px, $mob);
  cursor: pointer;
  z-index: 3;

  img {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    transition: .3s ease-out;

    &:first-child {
      opacity: 0;
    }
  }

  &.is_disabled {
    pointer-events: none;

    img {
      &:first-child {
        opacity: 1;
      }

      &:nth-child(2) {
        opacity: 0;
      }
    }
  }
}

.catalog-rare-mob-button_prev {
  left: get-vw(20px, $mob);
}

.catalog-rare-mob-button_next {
  right: get-vw(20px, $mob);
}