.order-summary-table {
  width: 100%;
  color: #BFC0C4;
  border-collapse: collapse;
  border-top: get-vw(1px, $mob) solid rgba(255, 255, 255, 0.3);

  td {
    vertical-align: top;
    padding: get-vw(10px, $mob) get-vw(5px, $mob);
    border-bottom: get-vw(1px, $mob) solid rgba(255, 255, 255, 0.3);

    &:not(:first-child) {
      text-align: center;
      border-left: get-vw(1px, $mob) solid rgba(255, 255, 255, 0.3);
    }

    &:last-child {
      text-align: right;
    }
  }

  &_no_border_l {
    border-left: none !important;
  }
}