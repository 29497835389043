.catalog-size-slider {
  // width: get-vw(200px);
  margin-left: auto;
  margin-right: auto;
  padding: 0 0 get-vw(7px);

  @include respond-max('tablet') {
    padding: 0 get-vw(10px, $mob);
  }
  
  .swiper-wrapper {
    align-items: center;
  }

  &__link {
    display: block;
    font-size: get-vw(20px);
    font-family: $disket;
    text-align: center;
    color: #7c7d7f80;

    @include respond-max('tablet') {
      font-size: get-vw(20px, $mob);
    }

    &.is_disabled {
      color: #E8585880 !important;
    }
  }

  .swiper-slide-active {
    .catalog-size-slider__link {
      font-weight: 700;
      font-size: get-vw(30px);
      color: #e0e0e0;
      
      @include respond-max('tablet') {
        font-size: get-vw(30px, $mob);
      }
    }
  }

  .swiper-slide-prev,
  .swiper-slide-next {
    .catalog-size-slider__link {
      font-size: get-vw(25px);
      color: #7c7d7f;

      @include respond-max('tablet') {
        font-size: get-vw(25px, $mob);
      }
    }
  }
}

.catalog-size {
  position: relative;

  &__line {
    img {
      width: 100%;
      display: block;
    }
  }

  &__items {
    color: color('gray', 'm-light');
  }

  &__item {
    position: absolute;
    cursor: pointer;
    transition: 0.3s ease-out;

    &:hover {
      color: color('white', 'base');
    }

    &.is_active {
      color: color('blue', 'sky');
    }

    &:first-child {
      top: 4%;
      left: 63%;
    }

    &:nth-child(2) {
      top: 12.3%;
      left: 43%;
    }

    &:nth-child(3) {
      top: 24.5%;
      left: 23%;
    }

    &:nth-child(4) {
      top: 40.2%;
      left: 8%;
    }

    &:nth-child(5) {
      top: 56.5%;
      left: 3%;
    }

    &:nth-child(6) {
      top: 78%;
      left: 2%;
    }
  }

  &__content {
    position: absolute;
    top: 37%;
    left: 48%;
  }
}
