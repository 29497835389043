.header-overlay {
  position: absolute;
  top: get-vw(-1px);
  left: get-vw(-2px);
  right: 0;
  z-index: 3;
  height: get-vw(59px);
  // background-color: #141517;
  // background-color: rgba(#141517, 0.9);
  // backdrop-filter: blur(10px);
  background: url('../images/header/header.svg') no-repeat;
  background-size: 100%;
  pointer-events: none;
  // opacity: 0;
  transition: 0.3s ease-out;

  // @include respond-min('tablet') {
  //   &_full {
  //     opacity: 1;
  //     pointer-events: all;
  //   }
  // }

  @include respond-max('tablet') {
    position: fixed;
    opacity: 1;
    pointer-events: all;
    z-index: 8;
    top: get-vw(-2px, $mob);
    left: get-vw(-2px, $mob);
    right: get-vw(-2px, $mob);
    height: get-vw(59px, $mob);
    background: url('../images/header/header-mob.svg') no-repeat;
    background-size: 100%;
  }
}

.header-logo {
  width: get-vw(132px);
  position: absolute;
  top: get-vw(14px);
  left: 0;
  right: 0;
  margin: auto;
  z-index: 10;

  img {
    display: block;
    width: 100%;
  }

  @include respond-max('tablet') {
    width: get-vw(66px, $mob);
    position: fixed;
    top: get-vw(17px, $mob);
  }
}

.header-menu {
  position: absolute;
  z-index: 3;
  top: get-vw(55px);
  left: get-vw(165px);

  &__list {
    display: flex;
    gap: get-vw(60px);
  }

  &__link {
    transition: 0.3s ease-out;

    @media (hover: hover) {
      &:hover {
        opacity: 0.4;
      }
    }
  }
}

.header-burger {
  position: absolute;
  top: get-vw(15px);
  left: get-vw(19px);
  cursor: pointer;
  z-index: 11;
  color: #0f1014;
  // width: get-vw(65px);
  // height: get-vw(55px);

  width: get-vw(48px);
  height: get-vw(30px);

  @include respond-max('tablet') {
    position: fixed;
    top: get-vw(14px, $mob);
    left: get-vw(14px, $mob);
    width: get-vw(43px, $mob);
    height: get-vw(26px, $mob);
  }

  &__icon {
    width: 100%;
    height: 100%;
    transition: 0.3s ease-out;
  }

  &__close {
    width: get-vw(65px);
    height: get-vw(55px);
    position: absolute;
    top: 0;
    left: get-vw(-5px);
    pointer-events: none;
    opacity: 0;
    transition: 0.3s ease-out;
    transform: scale(1.2);

    @include respond-max('tablet') {
      width: get-vw(43px, $mob);
      height: get-vw(37px, $mob);
    }
  }
}

.header-controls {
  position: absolute;
  top: get-vw(10px);
  right: get-vw(20px);
  gap: get-vw(14px);
  z-index: 10;
  display: flex;
  align-items: center;

  @include respond-max('tablet') {
    position: fixed;
    gap: get-vw(6px, $mob);
    top: get-vw(8px, $mob);
    right: get-vw(10px, $mob);
  }
}

.header-control-search {
  display: flex;
  align-items: center;
}

.header-control {
  cursor: pointer;
  width: get-vw(32px);
  height: get-vw(42px);
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  @media (hover: hover) {
    transition: 0.3s ease-out;

    &:hover {
      opacity: 0.6;
    }

    &:active {
      transform: scale(0.95);
    }
  }

  @include respond-max('tablet') {
    width: get-vw(24px, $mob);
    height: get-vw(22px, $mob);
  }

  &__number {
    color: #cae858;
    font-size: get-vw(10px);
    font-family: $disket;
    position: absolute;
    inset: 0;
    padding-bottom: get-vw(4px);
    padding-right: get-vw(8px);
    display: flex;
    align-items: center;
    justify-content: center;

    @include respond-max('tablet') {
      font-size: get-vw(8px, $mob);
      padding-bottom: get-vw(2px, $mob);
      padding-right: get-vw(6px, $mob);
    }
  }

  svg {
    position: relative;
    display: block;
    fill: none;
  }

  .icon-header-search {
    width: get-vw(26px);
    height: get-vw(26px);

    @include respond-max('tablet') {
      width: get-vw(19px, $mob);
      height: get-vw(19px, $mob);
    }
  }

  .icon-cart {
    width: get-vw(32px);
    height: get-vw(32px);

    @include respond-max('tablet') {
      width: get-vw(25px, $mob);
      height: get-vw(19px, $mob);
    }
  }

  .icon-user {
    width: get-vw(22px);
    height: get-vw(24px);

    @include respond-max('tablet') {
      width: get-vw(17px, $mob);
      height: get-vw(19px, $mob);
    }
  }
}

.header-search {
  opacity: 0;
  pointer-events: none;
  width: 0;
  display: flex;
  justify-content: flex-end;
  transition: 0.3s ease-out;

  &_is_visible {
    opacity: 1;
    width: get-vw(537px);
    margin-left: get-vw(14px);
    pointer-events: all;

    @include respond-max('tablet') {
      width: get-vw(150px, $mob);
      margin-left: get-vw(6px, $mob);
    }
  }

  .form-input {
    width: get-vw(537px);
    height: get-vw(27px);
    border-radius: get-vw(8px);

    @include respond-max('tablet') {
      width: get-vw(150px, $mob);
      height: get-vw(27px, $mob);
      border-radius: get-vw(8px, $mob);
      background-color: #000;
    }
  }
}
