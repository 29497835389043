.catalog-heading {
  width: get-vw(380px);
  position: relative;

  &_title {
    .catalog-heading__box {
      right: get-vw(40px);
      top: get-vw(15px);
      text-align: center;
    }
  }

  &__decor {
    width: 100%;

    img {
      width: 100%;
      display: block;
    }
  }

  &__box {
    position: absolute;
    top: get-vw(7px);
    left: get-vw(47px);
    letter-spacing: .12vw;
  }
}
