.swiper-button-prev {
  width: get-vw(21px);
  height: get-vw(45px);
  background: url('../images/slider-prev.svg') no-repeat;
  background-size: 100%;

  @include respond-max('tablet') {
    width: get-vw(11px, $mob);
    height: get-vw(25px, $mob);
  }

  &:after {
    display: none;
  }
}

.swiper-button-next {
  width: get-vw(21px);
  height: get-vw(45px);
  background: url('../images/slider-next.svg') no-repeat;
  background-size: 100%;

  @include respond-max('tablet') {
    width: get-vw(11px, $mob);
    height: get-vw(25px, $mob);
  }

  &:after {
    display: none;
  }
}
