.footer {
  position: relative;
}

.footer-block {
  height: get-vw(1080px);
  background-image: url('../images/footer/footer-bg.jpg');
  @include backcover();

  display: flex;
  align-items: center;
  position: relative;

  @include respond-max('tablet') {
    mask-image: url('../images/footer/footer-mask-bg.svg');
    mask-position: top;
    mask-size: 100%;
    mask-repeat: no-repeat;
    height: auto;
    padding: get-vw(60px, $mob) 0;
  }
}

.footer-container {
  width: 100%;
  padding: 0 get-vw(180px);

  @include respond-max('tablet') {
    padding: 0 get-vw(20px, $mob);
  }
}

.footer-menus {
  width: 50%;
  display: flex;
  flex-wrap: wrap;
  gap: get-vw(48px) 0;

  @include respond-max('tablet') {
    flex-direction: column;
    width: 100%;
    gap: get-vw(30px, $mob) 0;
  }
}

.footer-menu {
  width: 50%;
  padding-bottom: get-vw(15px);
  border-left: get-vw(1px) solid #7c7d7f;
  padding-left: get-vw(35px);

  @include respond-max('tablet') {
    width: 100%;
    padding-bottom: get-vw(15px, $mob);
    border-left: get-vw(1px, $mob) solid #7c7d7f;
    padding-left: get-vw(20px, $mob);

    &.is_opened {
      .footer-menu__title {
        &:after {
          transform: rotate(180deg);
        }
      }
    }
  }

  &__header {
    @include respond-min('tablet') {
      pointer-events: none;
    }
  }

  &__title {
    color: #cae858;
    margin-bottom: get-vw(16px);

    @include respond-max('tablet') {
      cursor: pointer;
      font-size: get-vw(18px, $mob);
      margin-bottom: 0;

      &:after {
        content: '';
        display: inline-block;
        vertical-align: middle;
        position: relative;
        top: get-vw(-2px, $mob);
        margin-left: get-vw(10px, $mob);
        width: get-vw(13px, $mob);
        height: get-vw(7px, $mob);
        background: url('../images/footer/footer-arrow.svg') no-repeat;
        background-size: 100%;
        transition: 0.3s ease-out;
      }
    }
  }

  &__content {
    @include respond-max('tablet') {
      display: none;
      padding-top: get-vw(20px, $mob);
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
    gap: get-vw(10px);

    @include respond-max('tablet') {
      gap: get-vw(10px, $mob);
    }
  }

  &__link {
    font-size: get-vw(19px);
    color: #edeef1;
    transition: 0.3s ease-out;

    @include respond-max('tablet') {
      font-size: get-vw(14px, $mob);
    }

    @media (hover: hover) {
      &:hover {
        opacity: 0.6;
      }
    }
  }
}

.footer-copy {
  @include respond-min('tablet') {
    position: absolute;
    bottom: get-vw(32px);
    left: get-vw(180px);
  }

  @include respond-max('tablet') {
    max-width: get-vw(240px, $mob);
    font-size: get-vw(14px, $mob);
    text-align: center;
    margin: get-vw(50px, $mob) auto 0;
  }
}

.footer-version {
  position: absolute;
  color: #3d3d3e;
  left: 50%;
  right: 0;
  top: 50%;
  text-align: center;

  @include respond-max('tablet') {
    left: auto;
    right: get-vw(20px, $mob);
    top: 0;
    text-align: right;
    color: #b6b6b6;
    font-size: get-vw(20px, $mob);
  }
}

@include respond-min('tablet') {
  .footer_theme_small {
    .footer-block {
      background-image: url('../images/footer/footer-bg-small.svg');
      height: get-vw(373px);
    }

    .footer-container {
      padding: 0 get-vw(360px);
    }

    .footer-menus {
      width: 100%;
      padding-top: get-vw(25px);
    }

    .footer-menu {
      width: 25%;
    }

    .footer-copy {
      bottom: auto;
      top: get-vw(17px);
      left: auto;
      right: get-vw(260px);
      color: #7c7d7f;
    }

    .footer-version {
      width: get-vw(230px);
      color: #b6b6b6;
      left: auto;
      top: get-vw(17px);
      right: 0;
    }
  }
}
