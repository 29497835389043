.tab-controls {
  display: flex;
  gap: get-vw(20px);

  @include respond-max('tablet') {
    gap: get-vw(8px, $mob);
    justify-content: center;
  }
}

.tab-control {
  width: get-vw(262px);
  height: get-vw(46px);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: #bfc0c4;
  font-size: get-vw(23px);
  font-weight: 400;
  line-height: get-vw(10px);
  background-image: url('../images/tab-control.svg');
  background-repeat: no-repeat;
  background-size: 100%;
  transition: 0.3s color ease-out;

  @media (hover: hover) {
    transition: 0.3s ease-out;

    &:not(.is_active) {
      &:hover {
        transform: scale(1.05);
      }
    }
  }

  &:nth-child(2) {
    background-image: url('../images/tab-control-2.svg');
  }

  @include respond-max('tablet') {
    width: get-vw(146px, $mob);
    height: get-vw(25px, $mob);
    font-size: get-vw(14px, $mob);
    line-height: get-vw(10px, $mob);
  }

  &:hover {
    color: #edeef1;
  }

  &.is_active {
    font-weight: 500;
    color: #edeef1;
    background-image: url('../images/tab-control-filled.svg');

    &:nth-child(2) {
      background-image: url('../images/tab-control-2-filled.svg');
    }
  }
}

[data-tab-content] {
  display: none;
}
