.order-products-slider-wrapper {
  padding: 0 get-vw(110px) 0 get-vw(140px);
  position: relative;
}

.order-products-slider {
  
  @include respond-max('tablet') {
    padding: 0;

    .swiper-slide {
      width: get-vw(150px, $mob);
    }

    .swiper-scrollbar {
      margin-left: get-vw(10px, $mob);
      margin-right: get-vw(10px, $mob);
      margin-top: get-vw(5px, $mob);
    }
  }

  @include respond-min('tablet') {
    .swiper-slide {
      width: get-vw(218px);
    }

    .swiper-scrollbar {
      display: none;
    }
  }
}

.order-products-slider-button {
  cursor: pointer;
  width: get-vw(15px);
  height: get-vw(32px);
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  color: #5E809F;

  svg {
    width: get-vw(15px);
    height: get-vw(32px);
  }

  @include respond-max('tablet') {
    display: none;
  }
}

.order-products-slider-button_prev {
  left: get-vw(40px);
}

.order-products-slider-button_next {
  right: 0;
}

.order-product-slide {
  display: block;
  padding-left: get-vw(5px);

  &__image {
    position: relative;
    width: get-vw(218px);
    height: get-vw(288px);
    margin-left: auto;
    margin-right: auto;
    margin-bottom: get-vw(5px);

    @include respond-max('tablet') {
      width: get-vw(150px, $mob);
      height: get-vw(201px, $mob);
      margin-bottom: get-vw(5px, $mob);
    }

    img {
      width: 100%;
      height: 100%;
      display: block;
      object-fit: cover;
    }
  }

  &__image-svg {
    height: 100%;
    position: absolute;
    top: 0;
    left: get-vw(-5px);
    right: 0;
    z-index: 1;

    @include respond-max('tablet') {
      left: get-vw(-5px, $mob)
    }

    svg {
      width: 100%;
      height: auto;
      display: block;
    }
  }

  &__image-mask {
    width: 100%;
    height: 100%;
    mask-image: url('../images/mask/order-image-mask.svg');
    mask-position: center;
    mask-size: cover;
  }

  &__title {
    font-size: get-vw(18px);
    color: #BFC0C4;
    text-align: center;
    font-family: $montserrat;
  }
}
