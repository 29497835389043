.catalog-category {
  position: absolute;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: get-vw(3px);
  color: color('gray', 'light');
  transition: .5s opacity ease-out, .2s color ease-out;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
  }

  &:hover {
    .catalog-category__title {
      color: color('white', 'base');
    }
  }

  &.is_hidden {
    opacity: 0;
    pointer-events: none;
  }

  &__icon {
    position: absolute;
    display: none;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  &__title {
    font-family: $disket;
    font-size: get-vw(16px);
    font-weight: 400;
    line-height: 1;
    transition: .2s color ease-out;
  }

  &__content {
    font-size: get-vw(8px);
    display: flex;
    align-items: center;
    gap: get-vw(10px);
    display: none;
  }

  &__tag {
    min-width: get-wv(42px);
    height: get-vw(13px);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding-left: get-vw(8px);
    padding-right: get-vw(8px);
    color: color('black', 'base');
    background: color('gray', 'light');
    clip-path: polygon(92% 0, 100% 25%, 100% 100%, 8% 100%, 0% 75%, 0 0);

    &.theme_style {
      clip-path: polygon(12% 0, 100% 0, 100% 64%, 88% 100%, 0 100%, 0 34%);
    }
  }
}
