.faq-box {
  position: relative;
  width: get-vw(1880px);
  height: get-vw(826px);
  padding-left: get-vw(55px);
  padding-right: get-vw(460px);
  padding-top: get-vw(70px);
  background: url('../images/faq/faq-bg.svg') no-repeat;
  background-size: 100%;
  margin-right: auto;
  margin-left: get-vw(35px);

  @include respond-min('tablet') {
    .personal-header__menu {
      left: 100%;
      margin-top: get-vw(-60px);
      margin-left: get-vw(40px);
    }

    .personal-menu__list {
      align-items: center;
    }

    .personal-body__scrollbar .simplebar-track.simplebar-vertical {
      right: auto;
      left: 0
    }
  }

  @include respond-max('tablet') {
    width: get-vw(340px, $mob);
    height: auto;
    background-color: #141414;
    background-image: url('../images/personal/personal-bg-mob.svg');
    background-repeat: no-repeat;
    padding: get-vw(54px, $mob) get-vw(10px, $mob) get-vw(40px, $mob);
    z-index: 1;

    &__infomenu {
      display: none;
    }
  }
}
