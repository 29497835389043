.input-counter {
  display: flex;
  align-items: center;

  &__input {
    width: get-vw(36px);
    height: get-vw(20px);
    text-align: center;
    color: #EDEEF1;
    background: transparent;

    @include respond-max('tablet') {
      width: get-vw(36px, $mob);
      height: get-vw(20px, $mob);
    }
  }

  &__button {
    width: get-vw(34px);
    height: get-vw(34px);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: #5E809F;
    // background: url('../images/icons/input-counter.svg') no-repeat;
    // background-size: 100%;
    border-radius: get-vw(4px);
    border: get-vw(0.76px) solid #E0E0E033;

    @include respond-max('tablet') {
      width: get-vw(34px, $mob);
      height: get-vw(34px, $mob);
      border-radius: get-vw(4px, $mob);
      border: get-vw(0.76px, $mob) solid #E0E0E033;
    }

    .icon-minus {
      width: get-vw(12px);
      height: get-vw(1px);

      @include respond-max('tablet') {
        width: get-vw(12px, $mob);
        height: get-vw(1px, $mob);
      }
    }

    .icon-plus,
    .icon-plus-m {
      width: get-vw(12px);
      height: get-vw(12px);

      @include respond-max('tablet') {
        width: get-vw(12px, $mob);
        height: get-vw(12px, $mob);
      }
    }

    .icon-minus-m {
      width: get-vw(12px);
      height: get-vw(3px);

      @include respond-max('tablet') {
        width: get-vw(12px, $mob);
        height: get-vw(3px, $mob);
      }
    }
  }
}

.input-counter_size_l {
  .input-counter__input {
    width: get-vw(57px);
    height: get-vw(57px);
    background-color: #141517;
    border: get-vw(1px) solid #3d3d3e;
    border-radius: get-vw(8px);

    @include respond-max('tablet') {
      width: get-vw(25px, $mob);
      height: get-vw(25px, $mob);
      font-size: get-vw(9px, $mob);
      border: none;
      background: transparent;
      border-radius: get-vw(8px, $mob);
    }
  }

  .input-counter__button {
    width: get-vw(30px);
    height: get-vw(30px);
    
    @include respond-min('tablet') {
      background: transparent;
    }

    @include respond-max('tablet') {
      width: get-vw(30px, $mob);
      height: get-vw(30px, $mob);

      .icon-minus {
        width: get-vw(8px, $mob);
        height: get-vw(1px, $mob);
      }

      .icon-plus {
        width: get-vw(8px, $mob);
        height: get-vw(8px, $mob);
      }
    }
  }
}
