.cart-product-sm {
  display: flex;
  gap: get-vw(15px);
  position: relative;

  @include respond-max('tablet') {
    gap: get-vw(15px, $mob);
    min-height: get-vw(182px, $mob);
    padding-bottom: get-vw(60px, $mob);
  }

  &_plug {
    height: get-vw(70px);
    overflow: hidden;

    @include respond-max('tablet') {
      height: get-vw(70px, $mob);
    }
  }

  &__image {
    position: relative;
    width: get-vw(159px);
    height: get-vw(211px);
    margin-left: get-vw(5px);

    @include respond-max('tablet') {
      width: get-vw(150px, $mob);
      height: get-vw(194px, $mob);
      margin-left: 0;
    }
  }

  &__image-svg {
    width: get-vw(163px);
    height: get-vw(213px);
    position: absolute;
    top: 0;
    left: get-vw(-5px);
    z-index: 1;

    @include respond-max('tablet') {
      width: get-vw(150px, $mob);
      height: get-vw(182px, $mob);
      left: 0;
    }

    svg {
      width: 100%;
      height: auto;
      display: block;
    }
  }

  &__image-mask {
    width: 105%;
    height: 100%;
    mask-image: url('../images/mask/order-image-mask.svg');
    mask-position: center;
    mask-size: 100%;
    margin-left: get-vw(-1px);

    @include respond-max('tablet') {
      width: calc(100% - #{get-vw(4px, $mob)});
      margin-left: get-vw(4px, $mob);
    }

    img {
      width: 100%;
      height: 100%;
      display: block;
      object-fit: cover;
    }
  }

  &__image-plus {
    width: get-vw(49px);
    height: get-vw(43px);
    background: url('../images/icons/add-lime-icon.svg') no-repeat;
    background-size: 100%;
    @include abscenter();

    @include respond-max('tablet') {
      width: get-vw(49px, $mob);
      height: get-vw(43px, $mob);
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
  }

  &__style {
    color: #606060;
  }

  &__desc {
    margin-bottom: get-vw(15px);

    span {
      font-weight: 500;
    }

    @include respond-max('tablet') {
      margin-bottom: get-vw(15px, $mob);
    }
  }

  &__price {
    width: get-vw(213px);
    height: get-vw(34px);
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url('../images/order/order-price-bg.svg');
    background-repeat: no-repeat;
    background-size: contain;

    @include respond-max('tablet') {
      position: absolute;
      bottom: 0;
      right: 0;
      width: get-vw(160px, $mob);
      height: get-vw(27px, $mob);
      font-size: get-vw(14px, $mob);
      background-image: url('../images/order/order-total-price-bg-m.svg');
    }
  }

  &__controls {
    display: flex;
    gap: get-vw(30px);
    margin-bottom: get-vw(10px);

    @include respond-max('tablet') {
      gap: get-vw(30px, $mob);
      margin-bottom: 0;
      position: absolute;
      bottom: 0;
      left: 0;
    }
  }
}
