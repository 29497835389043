.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: get-vw(10px);

  @include respond-max('tablet') {
    gap: get-vw(10px, $mob);
  }
}

.pagination-button {
  width: get-vw(35px);
  height: get-vw(35px);
  display: flex;
  align-items: center;
  justify-content: center;
  color: #707070;
  transition: 0.3s ease-out;

  @include respond-max('tablet') {
    width: get-vw(35px, $mob);
    height: get-vw(35px, $mob);
  }

  &:hover {
    color: #fff;
  }

  svg {
    width: get-vw(7px);
    height: get-vw(14px);

    @include respond-max('tablet') {
      width: get-vw(7px, $mob);
      height: get-vw(14px, $mob);
    }
  }
}

.pagination-list {
  display: flex;
  gap: get-vw(8px);
  font-size: get-vw(16px);
  font-weight: 600;
  font-family: $montserrat;
  color: #707070;

  @include respond-max('tablet') {
    gap: get-vw(8px, $mob);
    font-size: get-vw(16px, $mob);
  }

  &__item {
    &.is_active {
      .pagination-list__link {
        color: #f7f7f7;
      }
    }
  }

  &__link {
    width: get-vw(35px);
    height: get-vw(35px);
    display: flex;
    align-items: center;
    justify-content: center;
    border: get-vw(1px) solid #3d3d3e;
    border-radius: get-vw(6px);
    transition: 0.3s ease-out;

    @include respond-max('tablet') {
      width: get-vw(35px, $mob);
      height: get-vw(35px, $mob);
      border: get-vw(1px, $mob) solid #3d3d3e;
      border-radius: get-vw(6px, $mob);
    }

    &:hover {
      color: #fff;
      border-color: #fff;
    }
  }

  &__dots {
    padding: get-vw(6px) get-vw(5px) 0;

    @include respond-max('tablet') {
      padding: get-vw(6px, $mob) get-vw(5px, $mob) 0;
    }
  }
}

.pagination-load-more {
  display: flex;
  justify-content: center;

  &__link {
    color: #5e809f;
  }
}
