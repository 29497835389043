$animTime: .6s;

.password-ui {
  width: 100%;
  position: relative;

  .form-field__input {
    &:focus ~ .password-ui__icon-wrapper {
      .password-ui__circle,
      .password-ui__dot {
        animation: none;
      }

      .password-ui__circle-center {
        transform: translate3d(get-vw(-3px), 0, 0);

        @include respond-max('tablet') {
          transform: translate3d(get-vw(-3px, $mob), 0, 0);
        }
      }
    }
  }

  &__icon-wrapper {
    position: absolute;
    top: get-vw(12px);
    right: get-vw(12px);
    width: get-vw(24px);
    height: get-vw(24px);
    color: #bfc0c4;
    display: flex;
    align-items: center;
    justify-content: center;

    @include respond-max('tablet') {
      top: get-vw(8px, $mob);
      right: get-vw(8px, $mob);
      width: get-vw(24px, $mob);
      height: get-vw(24px, $mob);
    }

    @media (hover: hover) {
      &:hover {
        color: #edeef1;

        .password-ui__circle,
        .password-ui__dot {
          animation: none;
        }

        .password-ui__circle-center {
          transform: scale(1.4) !important;
        }
      }
    }
  }

  &__circle {
    width: get-vw(24px);
    height: get-vw(24px);
    overflow: hidden;
    cursor: pointer;
    position: relative;
    height: get-vw(24px);
    border-radius: 50%;
    border: 1px solid currentColor;
    transition: 0.3s ease-out;

    @include respond-max('tablet') {
      width: get-vw(24px, $mob);
      height: get-vw(24px, $mob);
    }
  }

  &__dot {
    width: get-vw(4px);
    height: get-vw(4px);
    border-radius: 50%;
    position: absolute;
    top: get-vw(2px);
    right: get-vw(2px);
    background-color: currentColor;
    transition: 0.3s ease-out;

    @include respond-max('tablet') {
      width: get-vw(4px, $mob);
      height: get-vw(4px, $mob);
      top: get-vw(2px, $mob);
      right: get-vw(2px, $mob);
    }
  }

  &__circle-center {
    width: get-vw(8px);
    height: get-vw(8px);
    border-radius: 50%;
    position: absolute;
    inset: 0;
    margin: auto;
    background-color: currentColor;
    transition: 0.3s ease-out;

    @include respond-max('tablet') {
      width: get-vw(8px, $mob);
      height: get-vw(8px, $mob);
    }
  }
}

.password-ui_animate {
  .password-ui__circle {
    animation: password $animTime;

    @include respond-max('tablet') {
      animation: passwordMob $animTime;
    }
  }

  .password-ui__dot {
    animation: passwordDot $animTime;
  }
}

.password-ui_opened {
  .password-ui__dot {
    animation: none;
  }

  .password-ui__circle {
    animation: none;

    .password-ui__circle-center {
      transform: translate3d(0, get-vw(-3px), 0);

      @include respond-max('tablet') {
        transform: translate3d(0, get-vw(-3px, $mob), 0);
      }
    }
  }
}

@keyframes passwordDot {
  0% {
    opacity: 1;
  }

  10% {
    opacity: 0;
  }

  90% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes password {
  0% {
    height: get-vw(24px);
  }

  10% {
    height: get-vw(20px);
  }

  20% {
    height: get-vw(15px);
  }

  30% {
    height: get-vw(8px);
  }

  40% {
    height: get-vw(5px);
  }

  50% {
    height: get-vw(1px);
  }

  60% {
    height: get-vw(5px);
  }

  70% {
    height: get-vw(8px);
  }

  80% {
    height: get-vw(15px);
  }

  90% {
    height: get-vw(20px);
  }

  100% {
    height: get-vw(24px);
  }
}

@keyframes passwordMob {
  0% {
    height: get-vw(24px, $mob);
  }

  10% {
    height: get-vw(20px, $mob);
  }

  20% {
    height: get-vw(15px, $mob);
  }

  30% {
    height: get-vw(8px, $mob);
  }

  40% {
    height: get-vw(5px, $mob);
  }

  50% {
    height: get-vw(1px, $mob);
  }

  60% {
    height: get-vw(5px, $mob);
  }

  70% {
    height: get-vw(8px, $mob);
  }

  80% {
    height: get-vw(15px, $mob);
  }

  90% {
    height: get-vw(20px, $mob);
  }

  100% {
    height: get-vw(24px, $mob);
  }
}
