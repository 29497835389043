.plug {
  width: 100%;
  height: 100%;

  @include respond-max('tablet') {
    margin-top: get-vw(-82px, $mob);
  }

  &__image {
    width: 100%;

    img {
      display: block;
      width: 100%;
    }
  }

  &__image_desktop {
    @include respond-max('tablet') {
      display: none;
    }
  }

  &__image_mob {
    @include respond-min('tablet') {
      display: none;
    }
  }

  &__form {
    width: get-vw(466px);
    position: absolute;
    top: get-vw(580px);
    left: get-vw(55px);
    right: 0;
    margin: auto;

    @include respond-max('tablet') {
      width: 100%;
      left: 0;
      top: get-vw(370px, 320px);
      padding: 0 get-vw(20px, 320px)
    }
  }

  &__input {
    width: 100%;
    height: get-vw(49px);
    background: #F4F4F4;
    padding: 0 get-vw(12px);

    @include respond-max('tablet') {
      font-size: get-vw(12px, 320px);
      height: get-vw(40px, 320px);
      padding: 0 get-vw(15px, 320px);
    }
  }

  &__button {
    cursor: pointer;
    width: get-vw(185px);
    background: transparent;
    border: none;
    margin-top: get-vw(20px);
    margin-left: auto;
    display: block;

    @include respond-max('tablet') {
      width: get-vw(165px, 320px);
      margin-top: get-vw(20px, 320px);
    }

    img {
      display: block;
      width: 100%;
    }
  }
}