.catalog-layer {
  position: absolute;
  top: get-vw(150px);
  left: get-vw(45px);
  right: 0;
  z-index: 2;

  &__preloader {
    @include abscenter();
    
    left: 29.6vw;
    z-index: 2;
  }

  &__background {
    position: relative;
    width: 100%;

    svg {
      width: 100%;
      height: auto;
      display: block;
      // backdrop-filter: blur(10px);
    }
  }

  &__container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: calc(100% - 3.2vw);
    z-index: 2;
    display: flex;
    padding: get-vw(53px) 0 0 get-vw(108px);
  }

  &__title {
    // text-align: right;
    // padding-left: get-vw(55px);
    margin-bottom: get-vw(34px);
    display: flex;
    justify-content: center;
  }

  &__left,
  &__center,
  &__right {
    // border: 1px solid color('white', 'base');
  }

  &__left {
    width: get-vw(470px);
    margin-right: 6vw;
  }

  &__center {
    width: get-vw(580px);
    height: get-vw(725px);
    position: relative;
    top: get-vw(-10px);
  }

  &__right {
    position: relative;
    top: get-vw(-4px);
    width: 23vw;
    margin-left: auto;
    display: flex;
    flex-direction: column;
  }

  &__image {
    width: 100%;
    height: 100%;
    position: relative;
    // border: get-vw(1px) solid color('gray', 'light');
    // border-radius: get-vw(22px);
    // mask-image: url('../images/mask/product-mask.svg');
    // mask-position: center;
    // mask-size: cover;

    // @include respond-max('tablet') {
    //   border-radius: get-vw(22px, $mob);
    // }

    img {
      max-width: 100%;
      max-height: 100%;
      display: block;
    }

    video {
      width: 100%;
      height: 100%;
      display: block;
      object-fit: cover;
      // background-color: #23282c;
      // border: get-vw(1px) solid #333333;
      border-radius: get-vw(22px);

      @include respond-max('tablet') {
        border-radius: get-vw(22px, $mob);
      }
    }
  }

  &__image-fill {
    overflow: hidden;
    border: none;

    img {
      width: 100%;
      height: 100%;
      max-width: initial;
      max-height: initial;
      position: absolute;
      inset: 0;
      object-fit: cover;
    }
  }

  &__status {
    position: absolute;
    bottom: get-vw(-42px);
    left: get-vw(6px);
  }

  &__heading {
    display: flex;
    justify-content: flex-end;
  }

  &__desc {
    margin-top: get-vw(80px);
    margin-left: get-vw(58px);
    width: get-vw(326px);
  }

  &__filter {
    // padding-left: get-vw(50px);
    margin-bottom: get-vw(52px);
  }

  // &__filter,
  // &__cards {
  //   padding-right: get-vw(9px);
  // }

  &__cards {
    height: 41.2vh;
  }

  &__scroll {
    .simplebar-track.simplebar-vertical {
      top: get-vw(-30px);
      bottom: get-vw(-30px);
      right: auto;
      left: 0;
    }
  }

  &__price {
    position: absolute;
    z-index: 2;
    left: 78%;
    bottom: get-vw(130px);
    width: get-vw(327px);
    text-align: center;
  }

  &__buttons {
    position: absolute;
    z-index: 2;
    right: get-vw(-30px);
    display: flex;
    bottom: get-vw(70px);
  }

  &__available {
    margin-right: get-vw(-22px);
  }

  &__spec {
    width: get-vw(200px);
    position: absolute;
    top: get-vw(27px);
    right: 0;
    color: color('gray', 'light');
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: get-vw(8px);
    font-size: get-vw(16px);
    line-height: get-vw(18px);
    text-transform: uppercase;

    img {
      width: get-vw(20px);
    }
  }

  &__guide {
    position: absolute;
    bottom: get-vw(5px);
    right: get-vw(40px);
  }

  &__guide-button {
    width: get-vw(120px);
    height: get-vw(48px);
    display: flex;
    justify-content: center;
    align-items: flex-end;
    padding-bottom: get-vw(12px);
    font-size: get-vw(12px);
    font-weight: 500;
    line-height: get-vw(16px);
    background: url('../images/button-guide.svg') no-repeat;
    background-size: 100%;
  }

  &__adapted {
    width: get-vw(81px);
    height: get-vw(37px);
    position: absolute;
    left: get-vw(15px);
    bottom: 0;
    background: url('../images/adapted-for.svg') no-repeat;
    background-size: 100%;
    font-size: get-vw(7px);
    font-weight: 400;
    padding-left: get-vw(20px);
  }

  &__fz {
    // margin-top: get-vw(-40px);
    width: get-vw(420px);
    width: get-vw(338px);
    // padding-left: get-vw(74px);
    position: relative;
    // margin-left: auto;
    margin-left: get-vw(24px)
  }

  // &__tab {
  //   padding-left: get-vw(44px);
  // }

  &__pagination {
    margin-top: get-vw(46px);
  }
}

.catalog-layer_product {
  top: get-vw(100px);

  .catalog-layer__left {
    width: get-vw(540px);
    margin-right: get-vw(70px);
    margin-top: get-vw(-2px);
  }
}
