.catalog-entering-style-nav {
  .catalog-category__title {
    color: #e0e0e0;
  }

  .catalog-category__code {
    color: color('blue', 'sky');
  }

  .catalog-category__tag {
    font-size: get-vw(10px);
    min-width: get-vw(58px);
    height: get-vw(17px);
    color: color('white');
    background-color: color('blue', 'sky');
  }

  .catalog-category {
    &:first-child {
      top: get-vw(81px);
      left: get-vw(1493px);
      pointer-events: none;

      &:before {
        width: get-vw(22px);
        height: get-vw(22px);
        left: get-vw(-40px);
        top: get-vw(-14px);
      }

      .catalog-category__title {
        font-size: get-vw(40px);
        font-weight: 700;
        color: color('white', 'm-dark');
      }
    }

    &:nth-child(2) {
      top: get-vw(500px);
      left: get-vw(890px);

      &:before {
        width: get-vw(74px);
        height: get-vw(74px);
        left: get-vw(-82px);
        top: get-vw(-30px);
      }
    }

    &:nth-child(3) {
      top: get-vw(691px);
      left: get-vw(823px);
    }
  }
}
