.catalog-product-swiper,
.catalog-mob-product-swiper {
  position: relative;
  width: 100%;
  height: 100%;
  mask-image: url('../images/mask/product-mask.svg');
  mask-position: center;
  mask-size: cover;

  .swiper {
    height: 100%;
    overflow: hidden;
    // border: get-vw(0.25px, $mob) solid #9b9b9b;
  }

  .swiper-pagination {
    position: absolute;
    bottom: get-vw(15px);
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    gap: get-vw(2px);
    z-index: 2;

    @include respond-max('tablet') {
      gap: get-vw(2px, $mob);
      bottom: get-vw(15px, $mob);
    }
  }

  .swiper-pagination-bullet {
    width: get-vw(20px);
    height: get-vw(5px);
    background-image: url('../images/icons/swiper-bullet.svg');
    background-size: contain;
    background-repeat: no-repeat;

    @include respond-max('tablet') {
      width: get-vw(20px, $mob);
      height: get-vw(5px, $mob);
    }
  }

  .swiper-pagination-bullet-active {
    background-image: url('../images/icons/swiper-bullet-active.svg');
  }

  .swiper-zoom-container {
    picture {
      width: 100%;
      height: 100%;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
