.filter-checkbox {
  cursor: pointer;
  color: #9b9b9b;

  input {
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 0;

    &:disabled + .filter-checkbox__title {
      &:before {
        content: '';
        width: get-vw(18px);
        height: get-vw(21px);
        display: block;
        background-image: url('../images/icons/lock.svg');
        background-repeat: no-repeat;
        background-size: 100%;

        @include respond-max('tablet') {
          width: get-vw(18px, $mob);
          height: get-vw(21px, $mob);
        }
      }
    }

    &:checked + .filter-checkbox__title {
      color: #fff;
      background-image: url('../images/icons/filter-checkbox-checked.svg');
    }
  }

  &__title {
    width: get-vw(135px);
    height: get-vw(45px);
    display: flex;
    align-items: center;
    justify-content: center;
    background: url('../images/icons/filter-checkbox.svg') no-repeat;
    background-size: 100%;

    @include respond-max('tablet') {
      width: get-vw(84px, $mob);
      height: get-vw(28px, $mob);
    }
  }
}
