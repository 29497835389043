.entering-categories {
  position: absolute;
  top: get-vw(375px);
  left: get-vw(320px);

  &__title {
    width: get-vw(478px);
    margin-bottom: get-vw(25px);
  }

  &__content {
    width: get-vw(365px);
  }

  &__text {
    p:not(:last-child) {
      margin-bottom: get-vw(30px);
    }
  }

  &__button {
    display: flex;
    justify-content: flex-end;
    margin-top: get-vw(65px)
  }
}