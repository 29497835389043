.catalog-version {
  font-size: get-vw(7px);
  font-weight: 300;
  line-height: get-vw(7px);
  text-align: right;
  position: absolute;
  bottom: get-vw(40px);
  right: get-vw(44px);
  color: color('gray', 'light');
}
