.catalog-fz {
  display: flex;
  justify-content: flex-end;
  position: relative;
  // margin-top: get-vw(-2px);
  z-index: 2;

  &__control {
    cursor: pointer;
    position: relative;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgba(color('white', 'base'), 0.5);
    width: get-vw(208px);
    height: get-vw(52px);
    padding: get-vw(8px) get-vw(35px);
    background-repeat: no-repeat;
    background-size: 100%;
    transition: .3s color ease-out;

    &:hover {
      color: color('white', 'base');
    }

    &.is_disabled {
      pointer-events: none;
      justify-content: flex-start;
    }

    &.is_active {
      color: color('white', 'base');
      z-index: 2;
    }
  }

  &__control-lock {
    width: get-vw(18px);
    vertical-align: middle;
    position: relative;
    top: get-vw(-2px);
    left: get-vw(12px);
    // position: absolute;
    // top: 0;
    // right: get-vw(15px);
    // bottom: 0;
    // margin: auto;
    // margin-left: get-vw(10px);
  }

  &__control-lock-center {
    position: absolute;
    top: 0;
    right: 0;
    left: get-vw(15px);
    bottom: 0;
    margin: auto;
  }

  &__control_fabric {
    background-image: url('../images/catalog/fabric.svg');

    &.is_active {
      background-image: url('../images/catalog/fabric-active.svg');
    }
  }

  &__control_size {
    // justify-content: flex-end;
    background-image: url('../images/catalog/size.svg');
    margin-left: get-vw(-30px);
    padding-left: get-vw(45px);

    &.is_active {
      background-image: url('../images/catalog/size-active.svg');
    }
  }
}
