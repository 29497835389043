.entering-mob {
  position: relative;
  margin-top: get-vw(60px, $mob);
  margin-bottom: get-vw(60px, $mob);
}

.entering-mob-nav {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 3;

  &__title {
    color: #558b97;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: get-vw(10px, $mob);
    margin-bottom: get-vw(10px, $mob);
  }

  &__list {
    display: flex;
    align-items: center;
    padding: 0 get-vw(30px, $mob);
  }

  &__button {
    width: get-vw(40px, $mob);
    height: get-vw(40px, $mob);
    display: flex;
    align-items: center;
    justify-content: center;
    color: #7c7d7f;

    svg {
      width: get-vw(8px, $mob);
      height: get-vw(15px, $mob);
    }
  }
}

.entering-mob-svg {
  height: get-vw(565px, $mob);
  position: absolute;
  top: get-vw(100px, $mob);
  left: 0;
  right: 0;
  overflow: hidden;

  &__block {
    width: get-vw(560px, $mob);
    height: get-vw(565px, $mob);
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate3d(-50%, 0, 0);

    svg {
      width: 100%;
      height: 100%;
      transform: translateZ(0);
    }
  }

  &__points {
    position: absolute;
    z-index: 2;
  }
}

.entering-mob-point {
  offset-distance: 75%;
  offset-path: path(
    'M0,279.486a279.486,279.486 0 1,0 558.972,0a279.486,279.486 0 1,0 -558.972,0Z'
  );

  &:first-child {
    offset-distance: 46%;
  }

  &:nth-child(2) {
    offset-distance: 66%;
  }

  &:nth-child(3) {
    offset-distance: 75%;
  }

  &:nth-child(4) {
    offset-distance: 84%;
  }

  &:nth-child(5) {
    offset-distance: 94%;
  }
}

.entering-mob-container {
  position: relative;
  z-index: 2;
  padding-top: get-vw(32px, $mob);
}

.entering-mob-block {
  position: relative;
  min-height: get-vw(565px, $mob);
}

.entering-mob-title {
  text-align: center;
  margin-bottom: get-vw(60px, $mob);
}

.entering-mob-styles {
  position: relative;
  z-index: 2;
  height: get-vw(50px, $mob);
  margin-bottom: get-vw(20px, $mob);

  &__item {
    width: get-vw(36px, $mob);
    height: get-vw(36px, $mob);
    color: #7c7d7f;
    font-family: $disket;
    font-size: get-vw(20px, $mob);
    position: absolute;
    cursor: pointer;
    opacity: 0;
    transition: 0.5s opacity ease-out, 0.2s color ease-out;

    span {
      position: absolute;
      top: get-vw(50px, $mob);
      left: 50%;
      transform: translate3d(-50%, 0, 0);
      display: none;
    }

    &.is_disabled,
    &.is_hidden {
      opacity: 0;
      pointer-events: none;
    }

    &:first-child {
      right: get-vw(6px, $mob);
      top: get-vw(50px, $mob);
    }

    &:nth-child(2) {
      right: get-vw(68px, $mob);
      top: get-vw(20px, $mob);
    }

    &:nth-child(3) {
      top: 0;
      left: 0;
      right: 0;
      margin: auto;
      text-align: center;
      color: #edeef1;
      pointer-events: none;
      opacity: 1;

      span {
        display: block;
      }
    }

    &:nth-child(4) {
      left: get-vw(73px, $mob);
      top: get-vw(18px, $mob);
    }

    &:nth-child(5) {
      left: get-vw(5px, $mob);
      top: get-vw(50px, $mob);
    }
  }
}

.entering-mob-slider {
  position: relative;
  min-height: get-vw(455px, $mob);
  margin-bottom: get-vw(80px, $mob);

  .swiper-pagination {
    position: static;
    margin-top: get-vw(12px, $mob);
  }
}

.entering-mob-slide {
  &__image {
    height: get-vw(398px, $mob);

    img {
      width: 100%;
      height: 100%;
      display: block;
      object-fit: contain;
    }
  }
}

.entering-mob-code {
  font-size: get-vw(8px, $mob);
  color: #47565e;
  text-align: center;
  margin-top: get-vw(16px, $mob);
}

.entering-mob-content {
  padding: 0 get-vw(40px, $mob);

  &__button {
    display: flex;
    justify-content: center;
    margin-bottom: get-vw(60px, $mob);
  }
}
