.catalog-filter {
  &__top {
    margin-bottom: get-vw(25px);

    @include respond-max('tablet') {
      margin-bottom: get-vw(20px, $mob);
    }
  }

  &__radios {
    display: flex;
    gap: get-vw(5px);

    @include respond-max('tablet') {
      gap: get-vw(5px, $mob);
    }
  }

  &__search {
    position: relative;
  }
}

.catalog-filter-block {
  position: relative;

  &__switcher {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: get-vw(-0.5px);

    @include respond-max('tablet') {
      margin-left: get-vw(-0.5px, $mob);
      margin-right: get-vw(-0.5px, $mob);
    }
  }

  &__dropdown {
    height: get-vw(525px);
    position: absolute;
    background-color: #090A10;
    top: get-vw(38px);
    left: 0;
    right: get-vw(0.5px);
    z-index: 2;
    padding: get-vw(25px) get-vw(30px) get-vw(35px);
    border: get-vw(0.5px) solid #5E809F;
    opacity: 0;
    pointer-events: none;
    transition: 0.3s ease-out;

    @include respond-max('tablet') {
      height: auto;
      top: get-vw(25.5px, $mob);
      padding: get-vw(25px, $mob) get-vw(25px, $mob) get-vw(55px, $mob);
      border: get-vw(0.5px, $mob) solid #5E809F;
    }

    &.is_opened {
      opacity: 1;
      pointer-events: all;
    }
  }

  &__dropdown-item {
    display: none;
  }

  &__dropdown-submit {
    width: get-vw(160px);
    height: get-vw(32px);
    position: absolute;
    bottom: get-vw(18px);
    right: get-vw(18px);
    font-size: get-vw(16px);
    cursor: pointer;
    color: #7c7d7f;
    background: url('../images/catalog/catalog-filter-submit.svg') no-repeat;
    background-size: 100%;
    transition: 0.3s ease-out;

    @include respond-max('tablet') {
      width: get-vw(160px, $mob);
      height: get-vw(32px, $mob);
      bottom: get-vw(8px, $mob);
      right: get-vw(8px, $mob);
      font-size: get-vw(16px, $mob);
    }

    &:hover {
      color: #fff;
    }
  }
}

.catalog-filter-button {
  width: get-vw(150px);
  height: get-vw(39px);
  position: relative;
  cursor: pointer;
  color: #f7f7f780;
  transition: 0.3s ease-out;

  @include respond-max('tablet') {
    width: get-vw(101px, $mob);
    height: get-vw(26px, $mob);
  }

  &__close {
    width: get-vw(10px);
    height: get-vw(10px);
    position: absolute;
    top: 0;
    bottom: 0;
    left: get-vw(14px);
    z-index: 2;
    margin: auto;
    transition: 0.3s ease-out;
    opacity: 0;

    @include respond-max('tablet') {
      display: none;
    }

    svg {
      width: 100%;
      height: 100%;
      display: block;
    }
  }

  &__counter {
    width: get-vw(16px);
    position: absolute;
    top: get-vw(-7px);
    right: get-vw(-8px);
    z-index: 2;
    text-align: center;
    color: #fff;

    @include respond-max('tablet') {
      width: get-vw(16px, $mob);
      top: get-vw(-7px, $mob);
      right: get-vw(-8px, $mob);
    }
  }

  &.is_opened {
    .catalog-filter-button__title {
      color: #fff;
      font-weight: 500;
    }

    .catalog-filter-button__close {
      opacity: 1;
    }

    .catalog-filter-button__mask {
      fill: #090A10;

      path {
        stroke: #5E809F;
      }
    }

    &:before {
      content: '';
      position: absolute;
      bottom: 0;
      left: get-vw(1px);
      right: get-vw(0.5px);
      z-index: 3;
      height: get-vw(1px);
      background-color: #1d2023;

      @include respond-max('tablet') {
        left: get-vw(1px, $mob);
        right: get-vw(0.5px, $mob);
        height: get-vw(1px, $mob);
      }
    }
  }

  &:not(.is_opened) {
    &:hover {
      color: #fff;

      svg {
        stroke: #fff;
      }
    }

    &.is_active {
      .catalog-filter-button__title {
        color: #141414;
        font-weight: 500;
      }

      .catalog-filter-button__mask {
        fill: #5e809f;
        stroke: #ffffff33;
      }
    }
  }

  &.is_disabled {
    pointer-events: none;
  }

  &__title {
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__mask {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: auto;
    stroke: #ffffff33;
    fill: #1d2023;
  }
}

.catalog-filter-radios {
  &:not(:last-child) {
    margin-bottom: get-vw(15px);

    @include respond-max('tablet') {
      margin-bottom: get-vw(15px, $mob);
    }
  }

  &__title {
    padding: 0 get-vw(8px);
    margin-bottom: get-vw(16px);

    @include respond-max('tablet') {
      padding: 0 get-vw(8px, $mob);
      margin-bottom: get-vw(16px, $mob);
    }
  }

  &__group {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: get-vw(13px) get-vw(5px);

    @include respond-max('tablet') {
      gap: get-vw(13px, $mob) get-vw(5px, $mob);
      grid-template-columns: 1fr 1fr 1fr;
    }
  }
}
