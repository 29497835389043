.preloader-circle {
  width: 100px;
  height: 100px;
  @include abscenter();

  opacity: 0;
  pointer-events: none;
  transition: .3s ease-out;

  &.is_visible {
    opacity: 1;
  }

  &__outer {
    margin: auto;
    border: 16px solid rgba(0, 0, 0, 0);
    border-top: 16px solid #111;
    border-bottom: 16px solid #111;
    border-radius: 50%;
    border-style: double;
    width: 100%;
    height: 100%;
    animation: spin 5s linear infinite;
    @include abscenter();
  }

  &__inner {
    margin: auto;
    border: 10px solid rgba(0, 0, 0, 0);
    border-top: 10px solid #558B97;
    border-bottom: 10px solid #558B97;
    border-radius: 50%;
    width: 64%;
    height: 64%;
    animation: spin2 1.5s linear infinite;
    @include abscenter();
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes spin2 {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(-360deg);
  }
}
