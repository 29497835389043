.catalog-styles {
  width: get-vw(220px);
  position: absolute;
  top: get-vw(415px);
  left: 0;
  text-align: right;

  &__nav {
    display: flex;
    flex-direction: column;
    gap: get-vw(18px);
  }

  &__nav-item {
    &.is-active {
      transform: translate3d(get-vw(-25px), 0, 0);

      .catalog-styles__nav-link {
        opacity: 1;

        &:before {
          opacity: 1;
        }
      }
    }

    &:not(.is-active) {
      &:hover {
        .catalog-styles__nav-link {
          opacity: 0.7;
          padding-right: get-vw(25px);
        }
      }
    }
  }

  &__nav-link {
    font-size: get-vw(17px);
    color: color('white', 'm-dark');
    text-transform: uppercase;
    opacity: 0.5;
    position: relative;
    transition: 0.3s ease-out;

    &:before {
      content: '';
      width: get-vw(8px);
      height: get-vw(13px);
      position: absolute;
      top: 0;
      bottom: 0;
      right: get-vw(-24px);
      margin: auto;
      background: url('../images/icons/nav-left.svg') no-repeat;
      background-size: 100%;
      opacity: 0;
      transition: 0.3s ease-out;
    }
  }

  &__nav-info {
    display: block;
    font-size: get-vw(7.8px);
    position: absolute;
    bottom: 100%;
    right: 0;
    white-space: nowrap;
  }
}
